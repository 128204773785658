import { Button, Grid, Group, Paper, Text, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { useApp } from "../contexts/app.context";
import { DELETE_SHARE_LINK, GET_ALL_SHARE_LINKS, SAVE_SHARE_LINK } from "../services/share_links";
import { useClipboard } from "@mantine/hooks";

export default function AffiliatesPage() {
    const [shareLinks, setShareLinks] = useState<any[]>([]);
    const [selectedShareLink, setSelectedShareLink] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const { confirmDialog } = useApp();
    const clipboard = useClipboard();

    const handleChange = dt => setSelectedShareLink(sl => ({ ...sl, ...dt }))
    const handleChangeProps = dt => setSelectedShareLink(sl => ({ ...sl, props: { ...sl?.props, ...dt } }))

    const loadShareLinks = () => {
        GET_ALL_SHARE_LINKS()
            .then(res => {
                setShareLinks(res.filter(r => r.type === "affiliate"));
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
            })
    }

    const handleSave = () => {
        let params = { ...selectedShareLink, type: "affiliate" };

        setLoadingSave(true)
        SAVE_SHARE_LINK(params)
            .then((res) => {
                notifications.show({ message: "Affiliate saved!", color: "green" })
                loadShareLinks();
                setSelectedShareLink(res)
                setLoadingSave(false);
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
                setLoadingSave(false);
            })
    }

    useEffect(loadShareLinks, []);

    return <div style={{ position: 'relative' }}>
        <Grid>
            <Grid.Col span={{ base: 12 }}>
                <Group mb="md">
                    <InputField
                        name="pl"
                        style={{ flex: 1 }}
                        onChange={({ pl }) => {
                            pl === "new"
                                ? setSelectedShareLink({})
                                : setSelectedShareLink(shareLinks.find(p => p._id === pl))
                        }}
                        value={selectedShareLink ? selectedShareLink?._id || "new" : null}
                        fieldType={InputFieldTypes.SELECT}
                        clearable={false}
                        options={[
                            ...shareLinks.map(p => ({ label: p.props?.title, value: p._id })),
                            { value: "new", label: "Add New Affiliate" }
                        ]}
                    />
                    {selectedShareLink?._id && <Button color="gray" variant="outline"
                        onClick={() => {
                            confirmDialog(
                                { text: "Do you realy wants to delete this affiliate?" },
                                ({ confirmed }) => {
                                    if (confirmed) DELETE_SHARE_LINK(selectedShareLink?._id)
                                        .then(() => {
                                            loadShareLinks();
                                            setSelectedShareLink(null);
                                        })
                                        .catch(err => {
                                            notifications.show({ message: err.message })
                                        })
                                }
                            )
                        }}
                    >Delete Affiliate</Button>}
                </Group>

                {selectedShareLink && <>
                    {selectedShareLink._id && selectedShareLink.url && <Paper style={{ background: '#DFDFDF' }} p="md" mb="md">
                        <Group>
                            <Title style={{ flex: 1 }} order={5} c="gray">{selectedShareLink.url}</Title>
                            <Button onClick={() => {
                                clipboard.copy(selectedShareLink.url);
                                notifications.show({ message: "Copied to clipboard", color: "yellow" })
                            }} color="white" style={{ color: 'gray' }}>Copy</Button>
                        </Group>
                    </Paper>}
                    <Paper p="md" mb="md">
                        <InputField
                            name="title"
                            onChange={handleChangeProps}
                            value={selectedShareLink?.props?.title}
                            title="Affiliate Name"
                        />
                    </Paper>
                    <Paper p="md" mb="md">
                        <InputField
                            name="label"
                            onChange={handleChange}
                            value={selectedShareLink?.label}
                            title="Custom Label"
                        />
                    </Paper>
                    <Group justify='flex-end' mt="md">
                        <Button size="md" onClick={handleSave} loading={loadingSave} color="green" className="draw-button">
                            Save Affiliate
                        </Button>
                    </Group>
                </>}

            </Grid.Col>
        </Grid>
    </div>
}
