import { Box, Button, Grid, Group, Switch } from '@mantine/core';
import React, { useState } from 'react';
import BlogItemGeneration from '../components/blog_item_generation';
import InputField, { InputFieldTypes } from '../components/input_field';

export default function BlogGenerator() {
    const [items, setItems] = useState<any[]>([]);
    const [isDraft, setIsDraft] = useState<boolean>(false);
    const [insertingText, setInsertingText] = useState("");

    const submit = () => {
        let newItems = insertingText.split("\n").filter(t => t && t.length > 3).map(it => ({ title: it }));
        setItems(i => [...i, ...newItems]);
        setInsertingText("");
    }

    const description = "Enter blog topics (one per line, max 60 characters each): e.g. Top 10 React Hooks for Beginners, How to Optimize Your Website for Speed, The Future of AI in Web Development";

    return <div>
        <Group align='flex-end'>
            <InputField
                name="it"
                style={{ flex: 1 }}
                value={insertingText}
                fieldType={InputFieldTypes.TEXTAREA}
                onChange={({ it }) => setInsertingText(it)}
                placeholder={description}
                description={description}
            />
            <Box>
                <Switch mt="md" label="Is Draft" checked={isDraft} onChange={() => setIsDraft(id => !id)} />
                <Button mt="md" onClick={() => { submit() }} disabled={!insertingText}>Submit</Button>
            </Box>
        </Group>
        <Grid>
            {
                items.map(item => {
                    return <Grid.Col span={{ base: 12, md: 12 }}>
                        <BlogItemGeneration
                            title={item.title}
                            isDraft={isDraft}
                        />
                    </Grid.Col>
                })
            }
        </Grid>
    </div>
}
