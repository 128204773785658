import {
  Button,
  PasswordInput,
  Space,
  Title
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import { api } from "../services/api";

export default function Invite() {
  const { companyHost } = useAuth();
  const { key } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const [recoverData, setRecoverData] = useState<any>(null);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  React.useEffect(() => {
    if (key) {
      api.get(`/auth/recover/${key}`)
        .then(({ data }) => {
          setRecoverData(data);
        })
        .catch(() => {
          notifications.show({ title: "Oops", message: "Token expired, try again", color: 'red' });
          navigate("/")
        })
    }
  }, [key])

  return <div style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}>
    <div style={{ flex: isMobile ? 1 : 0.3, display: 'flex', flexDirection: 'column' }}>
      <div style={{ padding: 40 }}>
        <img alt="retainr" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{ maxHeight: 80, maxWidth: '70%' }} />
      </div>
      <div style={{ flex: 1, padding: 40 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            api.post("/auth/recover/confirm", {
              uuid: key,
              password,
            })
              .then(() => {
                navigate("/");
                notifications.show({ title: "Recover password", message: "Password updated!", color: 'green' })
              })
              .catch(err => {
                notifications.show({ title: 'Ops', message: 'Seu token expirou', color: 'red' })
              })
          }}
        >
          <Title order={3} mb="lg">Recover Password for {recoverData?.email}</Title>
          <PasswordInput
            mt="md"
            label="Choose a new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            mt="md"
            label="Confirm password"
            value={passwordConfirmation}
            error={passwordConfirmation && passwordConfirmation !== password && "You typed 2 different passwords"}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Space h="xl" />
          <Button
            type="submit"
            mt="xl"
            className="draw-button"
          >
            Recover Password
          </Button>
        </form>
      </div>
      <div style={{ padding: 40 }}>

      </div>
    </div>
    {!isMobile && <div style={{ flex: 0.7, background: `url(/assets/login.png)`, height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>}
  </div>
};
