import { Avatar, Box, Button, Group, Text, Title } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";
import { FaNodeJs } from "react-icons/fa";
import { MdWebhook } from "react-icons/md";

export default function Webhooks() {
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [action, setAction] = useState<string>("");

    const url = { ...data }[action]?.url;

    const loadData = () => {
        GET_COMPANY_INTEGRATION("webhooks")
            .then((res) => {
                setData(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        setLoadingSave(true)

        SET_COMPANY_INTEGRATION("webhooks", { ...data })
            .then((res) => {
                setLoadingSave(false);
                loadData()
                notifications.show({ message: "Webhook configured", color: 'green' })
            })
            .catch(err => {
                setLoadingSave(false)
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    React.useEffect(() => {
        try {
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            setIsValid(urlRegex.test(url));
        } catch (error) {
            setIsValid(false);
        }
    }, [url]);

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <MdWebhook size={30} />
            <Title order={5}>Webhooks</Title>
        </Group>
        <InputField
            mt="md"
            name="action"
            value={action}
            title="Action"
            onChange={({ action }) => setAction(action)}
            searchable={false}
            fieldType={InputFieldTypes.SELECT}
            options={[
                { value: "new_client", label: "New Client (new_client)" },
                { value: "new_support", label: "New Support (new_support)" },
                { value: "new_order", label: "New Order (new_order)" },
                { value: "accepted_order", label: "Accepted Order (accepted_order)" },
                { value: "declined_order", label: "Declined Order (declined_order)" },
            ]}
        />
        <InputField
            mt="md"
            name="url"
            value={url}
            title="Webhook POST URL"
            onChange={({ url }) => {
                setData(u => ({
                    ...u, [action]: {
                        ...{ ...u }.action,
                        url,
                    }
                }))
            }}
        />

        {exampleTexts[action] && <>
            <Text mt="md">Sample request:</Text>
            <Box style={{ background: '#EFEFEF' }} p="md" mt="md">
                {exampleTexts[action].map(t => <><div style={{ whiteSpace: 'pre-wrap' }}>{t}</div></>)}
            </Box>
        </>}

        <Group justify="flex-end" mt="md">
            <Button size="md" onClick={handleSave} disabled={!isValid} loading={loadingSave} color="green" className="draw-button">Publish Webhook</Button>
        </Group>
    </>
}

const exampleTexts = {
    new_client: [
        `{`,
        `    email: params.general.email,`,
        `    name: params.general.name,`,
        `    phone: params.general.phone,`,
        `    phone_code: params.general.phone_code,`,
        `    register_number: params.general.register_number,`,
        `    custom: params.custom,`,
        `}`,
    ],
    new_support: [
        `{`,
        `   id: "1",`,
        `   title: "Support Test",`,
        `   category: "Default Support",`,
        `   created_at: "2023-01-01T00:00:00-00",`,
        `}`,
    ],
    new_order: [
        `{`,
        `    id: "1",`,
        `    currency: "USD",`,
        `    total_price: 19.99,`,
        `    delivery_period: 2,`,
        `    title: "Order Test",`,
        `    description: "Order description",`,
        `    created_at: "2023-01-01T00:00:00-00",`,
        `}`,
    ],
    accepted_order: [
        `{`,
        `   id: "1",`,
        `}`,
    ],
    declined_order: [
        `{`,
        `   id: "1",`,
        `}`,
    ],
}
