import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

interface GroupByFuncProps<T> {
  (item: T): any
}
export const groupBy = <T>(
  list: T[],
  func: GroupByFuncProps<T>,
  keyValidator?: string,
): [any, T[]][] => {
  const map: any[] = [];
  list.forEach((item) => {
    const key = func(item);
    let i = -1;
    map.forEach((mapItem, index) => {
      if (i === -1 && (
        (mapItem[0] === key)
        || (keyValidator && mapItem[0][keyValidator] === key[keyValidator])
      )) i = index;
    });
    if (i === -1) map.push([key, [item]]);
    else map[i][1].push(item);
  });
  return map;
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function formatMoney(value = 0) {
  return (value).toLocaleString("en-US", { currency: "USD", style: 'currency', })
}

export const extenseWeekday = (weekday) => [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
][weekday];

export const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
]

export const spentTime = dt => {
  const months = moment().diff(moment(dt), "months")
  const ds = moment().diff(moment(dt), "days")
  const hours = moment().diff(moment(dt), "hours")
  const minutes = moment().diff(moment(dt), "minutes")
  return months > 0
    ? `${months} meses atrás`
    : ds > 0
      ? `${ds} dias atrás`
      : hours > 0 ? `${hours} horas atrás` : `${minutes} minutos atrás`
}

export function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = React.useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  React.useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}


export const getEntityProfile = item => ({
  "senador": "Senador",
  "deputado_federal": "Deputado Federal",
  "deputado_distrital": "Deputado Distrital",
  "orgao": "Órgão",
}[item.type] || item.type);

export const parseKeyword = (key) => key ? key
  .normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/[^\w\s]/gi, '')
  .toLowerCase() : "";

export const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [interacted, setInteracted] = useState(false);

  const toggle = () => setPlaying(!playing);
  const play = () => setPlaying(true);
  const pause = () => setPlaying(false);

  const handleInteraction = () => setInteracted(true);

  const setVolume = volume => {
    audio.volume = volume;
  }

  useEffect(() => {
    if (interacted) {
      audio.currentTime = 0;
      audio.loop = false;
      playing ? audio.play() : audio.pause();
    }
  }, [playing, interacted]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleInteraction);
    return () => { document.removeEventListener('click', handleInteraction); };
  }, [])

  return { playing, play, pause, toggle, setVolume };
};

export const formatMilliseconds = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
  const seconds = (totalSeconds % 60).toString().padStart(2, '0');
  return parseInt((totalSeconds / 3600).toFixed(0)) > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

export const getExtenseDatetime = (datetime, hours = true) => {
  const extenseDate = moment(datetime).format("YYYY-MM-DD") === moment().subtract(1, "days").format("YYYY-MM-DD")
    ? "Yesterday"
    : moment(datetime).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "Today"
      : moment(datetime).format("MMMM Do YYYY")
  return `${extenseDate}${hours ? `, ${moment(datetime).format("h:mm a")}` : ''}`;
}

export const markdown = (text): string => {
  if (!text) return "";
  text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  text = text.replace(/_(.*?)_/g, '<em>$1</em>');
  text = text.replace(/~(.*?)~/g, '<del>$1</del>');
  text = text.replace(/`(.*?)`/g, '<code>$1</code>');
  text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
  text = text.replace(/^\s*-\s(.+)$/gm, '<ul><li>$1</li></ul>');
  text = text.replace(/^\s*\d+\.\s(.+)$/gm, '<ol><li>$1</li></ol>');

  // Handle website links (http/https/www)
  text = text.replace(
    /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+)(?=$|\s)/g,
    (match, before, link) => {
      if (!link || link?.length < 1) {
        return '';
      }

      if (link.startsWith('www.')) {
        link = 'http://' + link;
      }
      return before + `<a href="${link}" target="_blank">${link}</a>`;
    }
  );

  return ReactHtmlParser(text);
};

export const getStatusColor = status => (
  status?.category === "done" ? "green" : status?.category === "in-progress" ? "orange" : "gray"
)

export const getTypeText = (type: string) => ({
  "text": "Texto",
  "video": "Vídeo",
  "audio": "Áudio",
  "image": "Imagem",
}[type] || "Arquivo")


export function formatFileSize(bytes: number): string {
  if (bytes < 1000) {
    return bytes + ' B';
  } else if (bytes < 1000 * 1000) {
    return (bytes / 1000).toFixed(2) + ' KB';
  } else if (bytes < 1000 * 1000 * 1000) {
    return (bytes / (1000 * 1000)).toFixed(2) + ' MB';
  } else {
    return (bytes / (1000 * 1000 * 1000)).toFixed(2) + ' GB';
  }
}

export function getCurrencies() {
  return [
    { value: "USD", label: "USD - United States Dollar" },
    { value: "AED", label: "AED - United Arab Emirates Dirham" },
    { value: "AFN", label: "AFN - Afghan Afghani" },
    { value: "ALL", label: "ALL - Albanian Lek" },
    { value: "AMD", label: "AMD - Armenian Dram" },
    { value: "ANG", label: "ANG - Netherlands Antillean Guilder" },
    { value: "AOA", label: "AOA - Angolan Kwanza" },
    { value: "ARS", label: "ARS - Argentine Peso" },
    { value: "AUD", label: "AUD - Australian Dollar" },
    { value: "AWG", label: "AWG - Aruban Florin" },
    { value: "AZN", label: "AZN - Azerbaijani Manat" },
    { value: "BAM", label: "BAM - Bosnia and Herzegovina Convertible Mark" },
    { value: "BBD", label: "BBD - Barbadian Dollar" },
    { value: "BDT", label: "BDT - Bangladeshi Taka" },
    { value: "BGN", label: "BGN - Bulgarian Lev" },
    { value: "BIF", label: "BIF - Burundian Franc" },
    { value: "BMD", label: "BMD - Bermudian Dollar" },
    { value: "BND", label: "BND - Brunei Dollar" },
    { value: "BOB", label: "BOB - Bolivian Boliviano" },
    { value: "BRL", label: "BRL - Brazilian Real" },
    { value: "BSD", label: "BSD - Bahamian Dollar" },
    { value: "BWP", label: "BWP - Botswanan Pula" },
    { value: "BYN", label: "BYN - Belarusian Ruble" },
    { value: "BZD", label: "BZD - Belize Dollar" },
    { value: "CAD", label: "CAD - Canadian Dollar" },
    { value: "CDF", label: "CDF - Congolese Franc" },
    { value: "CHF", label: "CHF - Swiss Franc" },
    { value: "CLP", label: "CLP - Chilean Peso" },
    { value: "CNY", label: "CNY - Chinese Yuan" },
    { value: "COP", label: "COP - Colombian Peso" },
    { value: "CRC", label: "CRC - Costa Rican Colón" },
    { value: "CVE", label: "CVE - Cape Verdean Escudo" },
    { value: "CZK", label: "CZK - Czech Republic Koruna" },
    { value: "DJF", label: "DJF - Djiboutian Franc" },
    { value: "DKK", label: "DKK - Danish Krone" },
    { value: "DOP", label: "DOP - Dominican Peso" },
    { value: "DZD", label: "DZD - Algerian Dinar" },
    { value: "EGP", label: "EGP - Egyptian Pound" },
    { value: "ETB", label: "ETB - Ethiopian Birr" },
    { value: "EUR", label: "EUR - Euro" },
    { value: "FJD", label: "FJD - Fijian Dollar" },
    { value: "FKP", label: "FKP - Falkland Islands Pound" },
    { value: "GBP", label: "GBP - British Pound Sterling" },
    { value: "GEL", label: "GEL - Georgian Lari" },
    { value: "GIP", label: "GIP - Gibraltar Pound" },
    { value: "GMD", label: "GMD - Gambian Dalasi" },
    { value: "GNF", label: "GNF - Guinean Franc" },
    { value: "GTQ", label: "GTQ - Guatemalan Quetzal" },
    { value: "GYD", label: "GYD - Guyanese Dollar" },
    { value: "HKD", label: "HKD - Hong Kong Dollar" },
    { value: "HNL", label: "HNL - Honduran Lempira" },
    { value: "HTG", label: "HTG - Haitian Gourde" },
    { value: "HUF", label: "HUF - Hungarian Forint" },
    { value: "IDR", label: "IDR - Indonesian Rupiah" },
    { value: "ILS", label: "ILS - Israeli New Shekel" },
    { value: "INR", label: "INR - Indian Rupee" },
    { value: "ISK", label: "ISK - Icelandic Króna" },
    { value: "JMD", label: "JMD - Jamaican Dollar" },
    { value: "JPY", label: "JPY - Japanese Yen" },
    { value: "KES", label: "KES - Kenyan Shilling" },
    { value: "KGS", label: "KGS - Kyrgyzstani Som" },
    { value: "KHR", label: "KHR - Cambodian Riel" },
    { value: "KMF", label: "KMF - Comorian Franc" },
    { value: "KRW", label: "KRW - South Korean Won" },
    { value: "KYD", label: "KYD - Cayman Islands Dollar" },
    { value: "KZT", label: "KZT - Kazakhstani Tenge" },
    { value: "LAK", label: "LAK - Lao Kip" },
    { value: "LBP", label: "LBP - Lebanese Pound" },
    { value: "LKR", label: "LKR - Sri Lankan Rupee" },
    { value: "LRD", label: "LRD - Liberian Dollar" },
    { value: "LSL", label: "LSL - Lesotho Loti" },
    { value: "MAD", label: "MAD - Moroccan Dirham" },
    { value: "MDL", label: "MDL - Moldovan Leu" },
    { value: "MGA", label: "MGA - Malagasy Ariary" },
    { value: "MKD", label: "MKD - Macedonian Denar" },
    { value: "MMK", label: "MMK - Myanma Kyat" },
    { value: "MNT", label: "MNT - Mongolian Tögrög" },
    { value: "MOP", label: "MOP - Macanese Pataca" },
    { value: "MUR", label: "MUR - Mauritian Rupee" },
    { value: "MVR", label: "MVR - Maldivian Rufiyaa" },
    { value: "MWK", label: "MWK - Malawian Kwacha" },
    { value: "MXN", label: "MXN - Mexican Peso" },
    { value: "MYR", label: "MYR - Malaysian Ringgit" },
    { value: "MZN", label: "MZN - Mozambican Metical" },
    { value: "NAD", label: "NAD - Namibian Dollar" },
    { value: "NGN", label: "NGN - Nigerian Naira" },
    { value: "NIO", label: "NIO - Nicaraguan Córdoba" },
    { value: "NOK", label: "NOK - Norwegian Krone" },
    { value: "NPR", label: "NPR - Nepalese Rupee" },
    { value: "NZD", label: "NZD - New Zealand Dollar" },
    { value: "PAB", label: "PAB - Panamanian Balboa" },
    { value: "PEN", label: "PEN - Peruvian Nuevo Sol" },
    { value: "PGK", label: "PGK - Papua New Guinean Kina" },
    { value: "PHP", label: "PHP - Philippine Peso" },
    { value: "PKR", label: "PKR - Pakistani Rupee" },
    { value: "PLN", label: "PLN - Polish Złoty" },
    { value: "PYG", label: "PYG - Paraguayan Guarani" },
    { value: "QAR", label: "QAR - Qatari Riyal" },
    { value: "RON", label: "RON - Romanian Leu" },
    { value: "RSD", label: "RSD - Serbian Dinar" },
    { value: "RUB", label: "RUB - Russian Ruble" },
    { value: "RWF", label: "RWF - Rwandan Franc" },
    { value: "SAR", label: "SAR - Saudi Riyal" },
    { value: "SBD", label: "SBD - Solomon Islands Dollar" },
    { value: "SCR", label: "SCR - Seychellois Rupee" },
    { value: "SEK", label: "SEK - Swedish Krona" },
    { value: "SGD", label: "SGD - Singapore Dollar" },
    { value: "SHP", label: "SHP - Saint Helena Pound" },
    { value: "SLE", label: "SLE - Sierra Leonean Leone" },
    { value: "SOS", label: "SOS - Somali Shilling" },
    { value: "SRD", label: "SRD - Surinamese Dollar" },
    { value: "STD", label: "STD - São Tomé and Príncipe Dobra" },
    { value: "SZL", label: "SZL - Swazi Lilangeni" },
    { value: "THB", label: "THB - Thai Baht" },
    { value: "TJS", label: "TJS - Tajikistani Somoni" },
    { value: "TOP", label: "TOP - Tongan Pa'anga" },
    { value: "TRY", label: "TRY - Turkish Lira" },
    { value: "TTD", label: "TTD - Trinidad and Tobago Dollar" },
    { value: "TWD", label: "TWD - New Taiwan Dollar" },
    { value: "TZS", label: "TZS - Tanzanian Shilling" },
    { value: "UAH", label: "UAH - Ukrainian Hryvnia" },
    { value: "UGX", label: "UGX - Ugandan Shilling" },
    { value: "UYU", label: "UYU - Uruguayan Peso" },
    { value: "UZS", label: "UZS - Uzbekistani Som" },
    { value: "VND", label: "VND - Vietnamese Dong" },
    { value: "VUV", label: "VUV - Vanuatu Vatu" },
    { value: "WST", label: "WST - Samoan Tala" },
    { value: "XAF", label: "XAF - Central African CFA Franc" },
    { value: "XCD", label: "XCD - East Caribbean Dollar" },
    { value: "XOF", label: "XOF - West African CFA Franc" },
    { value: "XPF", label: "XPF - CFP Franc" },
    { value: "YER", label: "YER - Yemeni Rial" },
    { value: "ZAR", label: "ZAR - South African Rand" },
    { value: "ZMW", label: "ZMW - Zambian Kwacha" },
  ]
}

export const countriesList = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Côte d\'Ivoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea, Democratic People\'s Republic of', value: 'KP' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Lao People\'s Democratic Republic', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia & South Sandwich Islands', value: 'GS' },
  { label: 'South Korea', value: 'KR' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard & Jan Mayen', value: 'SJ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syria', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad & Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks & Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'U.S. Outlying Islands', value: 'UM' },
  { label: 'U.S. Virgin Islands', value: 'VI' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United States', value: 'US' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Vatican City', value: 'VA' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Wallis & Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' }
]

export const phoneCountryCodes = [
  { "label": "(+93) Afghanistan", "value": "93" },
  { "label": "(+355) Albania", "value": "355" },
  { "label": "(+213) Algeria", "value": "213" },
  { "label": "(+376) Andorra", "value": "376" },
  { "label": "(+244) Angola", "value": "244" },
  { "label": "(+54) Argentina", "value": "54" },
  { "label": "(+374) Armenia", "value": "374" },
  { "label": "(+61) Australia", "value": "61" },
  { "label": "(+43) Austria", "value": "43" },
  { "label": "(+994) Azerbaijan", "value": "994" },
  { "label": "(+973) Bahrain", "value": "973" },
  { "label": "(+880) Bangladesh", "value": "880" },
  { "label": "(+375) Belarus", "value": "375" },
  { "label": "(+32) Belgium", "value": "32" },
  { "label": "(+501) Belize", "value": "501" },
  { "label": "(+229) Benin", "value": "229" },
  { "label": "(+975) Bhutan", "value": "975" },
  { "label": "(+591) Bolivia", "value": "591" },
  { "label": "(+387) Bosnia and Herzegovina", "value": "387" },
  { "label": "(+267) Botswana", "value": "267" },
  { "label": "(+55) Brazil", "value": "55" },
  { "label": "(+673) Brunei", "value": "673" },
  { "label": "(+359) Bulgaria", "value": "359" },
  { "label": "(+226) Burkina Faso", "value": "226" },
  { "label": "(+257) Burundi", "value": "257" },
  { "label": "(+855) Cambodia", "value": "855" },
  { "label": "(+237) Cameroon", "value": "237" },
  { "label": "(+238) Cape Verde", "value": "238" },
  { "label": "(+236) Central African Republic", "value": "236" },
  { "label": "(+235) Chad", "value": "235" },
  { "label": "(+56) Chile", "value": "56" },
  { "label": "(+86) China", "value": "86" },
  { "label": "(+57) Colombia", "value": "57" },
  { "label": "(+269) Comoros", "value": "269" },
  { "label": "(+506) Costa Rica", "value": "506" },
  { "label": "(+225) Côte d'Ivoire", "value": "225" },
  { "label": "(+385) Croatia", "value": "385" },
  { "label": "(+53) Cuba", "value": "53" },
  { "label": "(+357) Cyprus", "value": "357" },
  { "label": "(+420) Czech Republic", "value": "420" },
  { "label": "(+243) Democratic Republic of the Congo", "value": "243" },
  { "label": "(+45) Denmark", "value": "45" },
  { "label": "(+253) Djibouti", "value": "253" },
  { "label": "(+593) Ecuador", "value": "593" },
  { "label": "(+20) Egypt", "value": "20" },
  { "label": "(+503) El Salvador", "value": "503" },
  { "label": "(+240) Equatorial Guinea", "value": "240" },
  { "label": "(+291) Eritrea", "value": "291" },
  { "label": "(+372) Estonia", "value": "372" },
  { "label": "(+251) Ethiopia", "value": "251" },
  { "label": "(+679) Fiji", "value": "679" },
  { "label": "(+358) Finland", "value": "358" },
  { "label": "(+33) France", "value": "33" },
  { "label": "(+241) Gabon", "value": "241" },
  { "label": "(+220) Gambia", "value": "220" },
  { "label": "(+995) Georgia", "value": "995" },
  { "label": "(+49) Germany", "value": "49" },
  { "label": "(+233) Ghana", "value": "233" },
  { "label": "(+30) Greece", "value": "30" },
  { "label": "(+502) Guatemala", "value": "502" },
  { "label": "(+224) Guinea", "value": "224" },
  { "label": "(+245) Guinea-Bissau", "value": "245" },
  { "label": "(+592) Guyana", "value": "592" },
  { "label": "(+509) Haiti", "value": "509" },
  { "label": "(+504) Honduras", "value": "504" },
  { "label": "(+852) Hong Kong", "value": "852" },
  { "label": "(+36) Hungary", "value": "36" },
  { "label": "(+354) Iceland", "value": "354" },
  { "label": "(+91) India", "value": "91" },
  { "label": "(+62) Indonesia", "value": "62" },
  { "label": "(+98) Iran", "value": "98" },
  { "label": "(+964) Iraq", "value": "964" },
  { "label": "(+353) Ireland", "value": "353" },
  { "label": "(+972) Israel", "value": "972" },
  { "label": "(+39) Italy", "value": "39" },
  { "label": "(+81) Japan", "value": "81" },
  { "label": "(+962) Jordan", "value": "962" },
  { "label": "(+254) Kenya", "value": "254" },
  { "label": "(+686) Kiribati", "value": "686" },
  { "label": "(+965) Kuwait", "value": "965" },
  { "label": "(+996) Kyrgyzstan", "value": "996" },
  { "label": "(+856) Laos", "value": "856" },
  { "label": "(+371) Latvia", "value": "371" },
  { "label": "(+961) Lebanon", "value": "961" },
  { "label": "(+266) Lesotho", "value": "266" },
  { "label": "(+231) Liberia", "value": "231" },
  { "label": "(+218) Libya", "value": "218" },
  { "label": "(+423) Liechtenstein", "value": "423" },
  { "label": "(+370) Lithuania", "value": "370" },
  { "label": "(+352) Luxembourg", "value": "352" },
  { "label": "(+853) Macau", "value": "853" },
  { "label": "(+389) Macedonia", "value": "389" },
  { "label": "(+261) Madagascar", "value": "261" },
  { "label": "(+265) Malawi", "value": "265" },
  { "label": "(+60) Malaysia", "value": "60" },
  { "label": "(+960) Maldives", "value": "960" },
  { "label": "(+223) Mali", "value": "223" },
  { "label": "(+356) Malta", "value": "356" },
  { "label": "(+692) Marshall Islands", "value": "692" },
  { "label": "(+222) Mauritania", "value": "222" },
  { "label": "(+230) Mauritius", "value": "230" },
  { "label": "(+52) Mexico", "value": "52" },
  { "label": "(+691) Micronesia", "value": "691" },
  { "label": "(+373) Moldova", "value": "373" },
  { "label": "(+377) Monaco", "value": "377" },
  { "label": "(+976) Mongolia", "value": "976" },
  { "label": "(+382) Montenegro", "value": "382" },
  { "label": "(+212) Morocco", "value": "212" },
  { "label": "(+258) Mozambique", "value": "258" },
  { "label": "(+95) Myanmar", "value": "95" },
  { "label": "(+264) Namibia", "value": "264" },
  { "label": "(+674) Nauru", "value": "674" },
  { "label": "(+977) Nepal", "value": "977" },
  { "label": "(+31) Netherlands", "value": "31" },
  { "label": "(+64) New Zealand", "value": "64" },
  { "label": "(+505) Nicaragua", "value": "505" },
  { "label": "(+227) Niger", "value": "227" },
  { "label": "(+234) Nigeria", "value": "234" },
  { "label": "(+850) North Korea", "value": "850" },
  { "label": "(+47) Norway", "value": "47" },
  { "label": "(+968) Oman", "value": "968" },
  { "label": "(+92) Pakistan", "value": "92" },
  { "label": "(+680) Palau", "value": "680" },
  { "label": "(+970) Palestine", "value": "970" },
  { "label": "(+507) Panama", "value": "507" },
  { "label": "(+675) Papua New Guinea", "value": "675" },
  { "label": "(+595) Paraguay", "value": "595" },
  { "label": "(+51) Peru", "value": "51" },
  { "label": "(+63) Philippines", "value": "63" },
  { "label": "(+48) Poland", "value": "48" },
  { "label": "(+351) Portugal", "value": "351" },
  { "label": "(+974) Qatar", "value": "974" },
  { "label": "(+242) Republic of the Congo", "value": "242" },
  { "label": "(+40) Romania", "value": "40" },
  { "label": "(+7) Russia", "value": "7" },
  { "label": "(+250) Rwanda", "value": "250" },
  { "label": "(+685) Samoa", "value": "685" },
  { "label": "(+378) San Marino", "value": "378" },
  { "label": "(+239) São Tomé and Príncipe", "value": "239" },
  { "label": "(+966) Saudi Arabia", "value": "966" },
  { "label": "(+221) Senegal", "value": "221" },
  { "label": "(+381) Serbia", "value": "381" },
  { "label": "(+248) Seychelles", "value": "248" },
  { "label": "(+232) Sierra Leone", "value": "232" },
  { "label": "(+65) Singapore", "value": "65" },
  { "label": "(+421) Slovakia", "value": "421" },
  { "label": "(+386) Slovenia", "value": "386" },
  { "label": "(+677) Solomon Islands", "value": "677" },
  { "label": "(+252) Somalia", "value": "252" },
  { "label": "(+27) South Africa", "value": "27" },
  { "label": "(+82) South Korea", "value": "82" },
  { "label": "(+211) South Sudan", "value": "211" },
  { "label": "(+34) Spain", "value": "34" },
  { "label": "(+94) Sri Lanka", "value": "94" },
  { "label": "(+249) Sudan", "value": "249" },
  { "label": "(+597) Suriname", "value": "597" },
  { "label": "(+268) Swaziland", "value": "268" },
  { "label": "(+46) Sweden", "value": "46" },
  { "label": "(+41) Switzerland", "value": "41" },
  { "label": "(+963) Syria", "value": "963" },
  { "label": "(+886) Taiwan", "value": "886" },
  { "label": "(+992) Tajikistan", "value": "992" },
  { "label": "(+255) Tanzania", "value": "255" },
  { "label": "(+66) Thailand", "value": "66" },
  { "label": "(+670) Timor-Leste", "value": "670" },
  { "label": "(+228) Togo", "value": "228" },
  { "label": "(+676) Tonga", "value": "676" },
  { "label": "(+1868) Trinidad and Tobago", "value": "1868" },
  { "label": "(+216) Tunisia", "value": "216" },
  { "label": "(+90) Turkey", "value": "90" },
  { "label": "(+993) Turkmenistan", "value": "993" },
  { "label": "(+688) Tuvalu", "value": "688" },
  { "label": "(+256) Uganda", "value": "256" },
  { "label": "(+380) Ukraine", "value": "380" },
  { "label": "(+971) United Arab Emirates", "value": "971" },
  { "label": "(+44) United Kingdom", "value": "44" },
  { "label": "(+1) United States of America", "value": "1" },
  { "label": "(+598) Uruguay", "value": "598" },
  { "label": "(+998) Uzbekistan", "value": "998" },
  { "label": "(+678) Vanuatu", "value": "678" },
  { "label": "(+379) Vatican City", "value": "379" },
  { "label": "(+58) Venezuela", "value": "58" },
  { "label": "(+84) Vietnam", "value": "84" },
  { "label": "(+967) Yemen", "value": "967" },
  { "label": "(+260) Zambia", "value": "260" },
  { "label": "(+263) Zimbabwe", "value": "263" }
]

export const extenseRecurrenceType = (type: string) => ({
  "month": "Monthly",
  "week": "Weekly",
  "year": "Annually",
}[type] || "Monthly")

export const sectorList = [
  { label: "Mining", value: "Mining" },
  { label: "Educational Services", value: "Educational Services" },
  { label: "Agriculture, Forestry, Fishing and Hunting", value: "Agriculture, Forestry, Fishing and Hunting" },
  { label: "Accommodation and Food Services", value: "Accommodation and Food Services" },
  { label: "Real Estate and Rental and Leasing", value: "Real Estate and Rental and Leasing" },
  { label: "Other Services (except Public Administration)", value: "Other Services (except Public Administration)" },
  { label: "Arts, Entertainment and Recreation", value: "Arts, Entertainment and Recreation" },
  { label: "Healthcare and Social Assistance", value: "Healthcare and Social Assistance" },
  { label: "Professional, Scientific and Technical Services", value: "Professional, Scientific and Technical Services" },
  { label: "Administration, Business Support and Waste Management Services", value: "Administration, Business Support and Waste Management Services" },
  { label: "Information", value: "Information" },
  { label: "Transportation and Warehousing", value: "Transportation and Warehousing" },
  { label: "Construction", value: "Construction" },
  { label: "Retail Trade", value: "Retail Trade" },
  { label: "Wholesale Trade", value: "Wholesale Trade" },
  { label: "Utilities", value: "Utilities" },
  { label: "Finance and Insurance", value: "Finance and Insurance" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Technology", value: "Technology" },
  { label: "Online Retail", value: "Online Retail" },
  { label: "Industrial Machinery, Gas and Chemicals", value: "Industrial Machinery, Gas and Chemicals" },
  { label: "Specialist Engineering, Infrastructure and Contractors", value: "Specialist Engineering, Infrastructure and Contractors" },
  { label: "Advisory and Financial Services", value: "Advisory and Financial Services" },
  { label: "Life Sciences", value: "Life Sciences" },
  { label: "Business Franchises", value: "Business Franchises" },
  { label: "Consumer Goods and Services", value: "Consumer Goods and Services" },
].sort((a, b) => a.label > b.label ? 1 : -1)

export const formatLabel = txt => (txt ?? "")
  .toLowerCase()
  .replace(/ /g, "-")
  .replace(/\.|\,|\$|\||\(|\)|\%/g, "")
  .replace(/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
    , "")
  .split("-").filter(nn => nn).join("-");
