import React, { useState } from "react";
import { GET_COMPANY_AUTOMATIONS, SAVE_COMPANY_AUTOMATIONS } from "../services/companies";
import { notifications } from "@mantine/notifications";
import { ActionIcon, Box, Button, Divider, Group, Menu, Paper, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { FaArrowLeft, FaCheck, FaChevronRight, FaEllipsisH, FaExclamation, FaPlus, FaTimes } from "react-icons/fa";
import InputField, { InputFieldTypes } from "../components/input_field";
import { GET_ALL_USERS } from "../services/users";
import { GET_ALL_SERVICES } from "../services/services";
import { GET_JOB_STATUS_OPTIONS } from "../services/jobs";
import { getExtenseDatetime } from "../utility/util";

export default function Integrations() {
    const [services, setServices] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [selectedAutomation, setSelectedAutomation] = useState<any>(null);

    const customers = users.filter(u => u.profile?.title === "Client").map(u => ({ label: u.name, value: u._id }));
    const admins = users.filter(u => u.profile?.title !== "Client").map(u => ({ label: u.name, value: u._id }));

    const triggersOptions = [
        { value: "order-created", label: "Order Created" },
        { value: "order-accepted", label: "Order Accepted" },
        { value: "order-declined", label: "Order Declined" },
        { value: "order-status", label: "Order Status Changed" },
        { value: "order-new-message", label: "Order New Message" },
        // { value: "form-submited", label: "Form Submitted" },
        { value: "support-created", label: "Support Created" },
        { value: "support-solved", label: "Support Solved" },
        { value: "support-new-message", label: "Support New Message" },
        { value: "customer-created", label: "Customer Created" },
    ];

    const fieldTypes = [
        { label: "Service", value: "service._id", type: "id", options: services.filter(s => s.status === "active").map(s => ({ label: s.plan_name, value: s._id })) },
        { label: "Customer", value: "customer._id", type: "id", options: customers },
        { label: "Order Status", value: "order.status_id", type: "id", options: statusOptions },
        { label: "Order Title", value: "order.title", type: "text" },
        { label: "Order Description", value: "order.description", type: "text" },
        { label: "Message Text", value: "message.text", type: "text" },
        {
            label: "Message Direction", value: "message.direction", type: "id", options: [
                { value: "sent", label: "Sent" },
                { value: "received", label: "Received" },
            ]
        },
    ]

    const conditionsOptions = [
        { label: "Equals", value: "equals", types: ["text", "id", "boolean", "date", "datetime", "number"] },
        { label: "Contains", value: "contains", types: ["text", "id", "boolean", "date", "datetime", "number"] },
        { label: "Does not contain", value: "does-not-contain", types: ["text", "id", "boolean", "date", "datetime", "number"] },
        { label: "Exactly matches", value: "exactly-matches", types: ["text", "id", "boolean", "date", "datetime", "number"] },
        { label: "Does not exactly match", value: "does-not-exactly-match", types: ["text", "id", "boolean", "date", "datetime", "number"] },
        { label: "Is in", value: "is-in", types: ["text"] },
        { label: "Is not in", value: "is-not-in", types: ["text"] },
        { label: "Starts with", value: "starts-with", types: ["text"] },
        { label: "Does not starts with", value: "does-not-starts-with", types: ["text"] },
        { label: "Ends with", value: "ends-with", types: ["text"] },
        { label: "Does not ends with", value: "does-not-ends-with", types: ["text"] },
        { label: "Greather than", value: "greather-than", types: ["number"] },
        { label: "Less than", value: "less-than", types: ["number"] },
        { label: "After", value: "after-date", types: ["date", "datetime"] },
        { label: "Before", value: "before-date", types: ["date", "datetime"] },
        { label: "Equals", value: "equals-date", types: ["date", "datetime"] },
        { label: "Is true", value: "is-true", types: ["boolean"] },
        { label: "Is false", value: "is-false", types: ["boolean"] },
        { label: "Exists", value: "exists", types: ["text", "id", "boolean", "date", "datetime", "number"] },
        { label: "Does not exists", value: "does-not-exists", types: ["text", "id", "boolean", "date", "datetime", "number"] },
    ]

    const actionsOptions = [
        selectedAutomation?.props?.trigger?.key?.startsWith("order-") &&
        {
            label: "Send Order Message", value: "order-new-message", variables: [
                { label: 'Service Title', value: 'service.plan_name' },
                { label: 'Customer Name', value: 'customer.name' },
            ]
        },
        selectedAutomation?.props?.trigger?.key?.startsWith("order-") &&
        { label: "Assign Responsible", value: "order-assign-responsible", options: admins },
        selectedAutomation?.props?.trigger?.key?.startsWith("order-") &&
        { label: "Change Order Status", value: "order-change-status", options: statusOptions },

        selectedAutomation?.props?.trigger?.key?.startsWith("support-") &&
        {
            label: "Send Support Message", value: "support-new-message", variables: [
                { label: 'Customer Name', value: 'customer.name' },
            ]
        },
        selectedAutomation?.props?.trigger?.key?.startsWith("support-") &&
        { label: "Solve Support Ticket", value: "support-solve" },

        selectedAutomation?.props?.trigger?.key?.startsWith("customer-") &&
        { label: "Create Support Ticket", value: "support-create" },
    ].filter(nn => nn)

    const handleChange = dt => setSelectedAutomation(sa => ({ ...sa, ...dt }));
    const changeCondition = (dt, ci, cj) => setSelectedAutomation(sa => ({
        ...sa,
        props: {
            ...sa.props,
            conditions: (sa.props?.conditions ?? []).map((arr, i) => (
                ci === i ? arr.map((c, j) => cj === j ? { ...c, ...dt } : c) : arr
            ))
        }
    }))
    const changeAction = (dt, ai) => setSelectedAutomation(sa => ({
        ...sa,
        props: {
            ...sa.props,
            actions: (sa.props?.actions ?? [{}]).map((c, i) => (
                ai === i ? { ...c, ...dt } : c
            ))
        }
    }))

    const loadStatusOptions = () => {
        GET_JOB_STATUS_OPTIONS()
            .then(res => {
                setStatusOptions(res.map(u => ({ label: u.title, value: u._id })));
            })
            .catch(err => {
                notifications.show({ title: "Oops", message: err.message, color: 'red' })
            })
    }

    const loadData = () => {
        GET_COMPANY_AUTOMATIONS()
            .then((res) => {
                setItems(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const loadServices = () => {
        GET_ALL_SERVICES()
            .then((res) => {
                setServices(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const loadUsers = () => {
        GET_ALL_USERS()
            .then((res) => {
                setUsers(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        setLoadingSave(true)
        SAVE_COMPANY_AUTOMATIONS(selectedAutomation)
            .then((res) => {
                setLoadingSave(false);
                loadData();
                setSelectedAutomation(null);
                notifications.show({ message: "Automation Saved", color: 'green' })
            })
            .catch(err => {
                setLoadingSave(false)
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    React.useEffect(() => {
        loadServices();
        loadUsers();
        loadStatusOptions();
    }, [])
    React.useEffect(() => { loadData() }, [])
    React.useEffect(() => {
        setSelectedAutomation(sa => ({
            ...sa, props: { ...sa?.props, actions: [{}] }
        }))
    }, [selectedAutomation?.props?.trigger?.key?.split("-")[0]])

    return <div style={{ position: 'relative' }}>
        {
            !!selectedAutomation
                ? <>
                    <Group mb="lg">
                        <ActionIcon variant="outline" onClick={() => setSelectedAutomation(null)}><FaArrowLeft /></ActionIcon>
                        <InputField
                            style={{ flex: 1 }}
                            name="title"
                            size="sm"
                            placeholder="Automation Title"
                            onChange={handleChange}
                            value={selectedAutomation?.title}
                        />
                        <Menu>
                            <Menu.Target><ActionIcon><FaEllipsisH /></ActionIcon></Menu.Target>
                            <Menu.Dropdown style={{ width: 400, maxHeight: 400, overflowY: 'auto', paddingBottom: 20 }}>
                                <Text size="md" fw="bold" mb="md" ta="center">History</Text>
                                {(selectedAutomation?.logs ?? []).sort((a, b) => a.createdAt > b.createdAt ? 1 : -1).map(l => <>
                                    <Divider mt="md" mb="md" />
                                    <Text size="sm" fw="bold">Trigger</Text>
                                    <Text size="sm">{triggersOptions.find(to => to.value === l.props?.trigger?.key)?.label}</Text>
                                    <Text size="sm" fw="bold">Conditions</Text>
                                    <Text size="sm">{l.conditions ? "Valid" : 'Blocked'}</Text>
                                    <Text size="sm" fw="bold">Actions</Text>
                                    {(l.actions ?? []).length === 0 && <Text size="sm">None</Text>}
                                    {(l.actions ?? []).map(({ action, error }) => <Group>
                                        <Text style={{ flex: 1 }} size="sm">{actionsOptions.find(ao => ao.value === action.key).label}</Text>
                                        {
                                            !!error
                                                ? <Tooltip label={error}><ActionIcon variant="light" color="red"><FaExclamation /></ActionIcon></Tooltip>
                                                : <Tooltip label="Success"><ActionIcon variant="light" color="green"><FaCheck /></ActionIcon></Tooltip>
                                        }
                                    </Group>)}
                                    <Text c="gray" size="xs" ta="right" mt="md">{getExtenseDatetime(l.createdAt)}</Text>
                                </>)}
                            </Menu.Dropdown>
                        </Menu>
                    </Group>


                    <Paper p="md" mb="lg">
                        <Text size="md" fw="bold" mb="xs">Trigger</Text>

                        <InputField
                            name="key"
                            placeholder="Select one trigger"
                            onChange={({ key }) => {
                                setSelectedAutomation(sa => ({
                                    ...sa,
                                    title: sa.title ?? triggersOptions.find(t => t.value === key)?.label,
                                    props: { ...sa.props, trigger: { ...sa.props?.trigger, key } }
                                }))
                            }}
                            fieldType={InputFieldTypes.SELECT}
                            value={selectedAutomation?.props?.trigger?.key}
                            options={triggersOptions}
                        />
                    </Paper>

                    {
                        (selectedAutomation?.props?.conditions ?? []).length === 0
                            ? <Button
                                mb="md"
                                size="sm"
                                leftSection={<FaPlus />}
                                variant="outline"
                                onClick={() => setSelectedAutomation(sa => ({
                                    ...sa, props: {
                                        ...sa.props, conditions: [[{}]]
                                    }
                                }))}>Add Conditions</Button>
                            : <Paper p="md" mb="lg">
                                <Text size="md" fw="bold" mb="xs">Conditions</Text>

                                <Text fw="bold" c="orange" size="xs" mb="xs">Only continue if…</Text>
                                {
                                    (selectedAutomation?.props?.conditions ?? []).map((arr, ci) => <>
                                        {ci > 0 && <>
                                            <Divider size="sm" color="orange" mb="sm" mt="sm" />
                                            <Text fw="bold" c="oragen" size="xs" mb="xs">OR continue if…</Text>
                                        </>}
                                        {arr.map((condition, cj) => {
                                            let selectedFieldType = fieldTypes.find(ft => condition.key === ft.value);
                                            return <Group align="flex-start">
                                                <Box mb="md" style={{ flex: 1 }}>
                                                    <Paper p="xs" style={{ borderColor: "#DFDFDF" }}>
                                                        <InputField
                                                            name="key"
                                                            size="sm"
                                                            mb="xs"
                                                            onChange={dt => changeCondition(dt, ci, cj)}
                                                            value={condition.key}
                                                            fieldType={InputFieldTypes.SELECT}
                                                            options={fieldTypes}
                                                        />
                                                        <InputField
                                                            name="type"
                                                            size="sm"
                                                            mb="xs"
                                                            onChange={dt => changeCondition(dt, ci, cj)}
                                                            value={condition.type}
                                                            fieldType={InputFieldTypes.SELECT}
                                                            options={conditionsOptions.filter(co => co.types.includes(selectedFieldType?.type))}
                                                        />
                                                        <InputField
                                                            name="value"
                                                            size="sm"
                                                            mb="xs"
                                                            onChange={dt => changeCondition(dt, ci, cj)}
                                                            value={condition.value}
                                                            fieldType={selectedFieldType?.options ? InputFieldTypes.SELECT : InputFieldTypes.STRING}
                                                            options={(selectedFieldType?.options ?? [])}
                                                        />
                                                    </Paper>
                                                </Box>

                                                <ActionIcon variant="outline" size="lg" color="gray" onClick={() => setSelectedAutomation(sa => ({
                                                    ...sa,
                                                    props: {
                                                        ...sa.props,
                                                        conditions: sa.props.conditions
                                                            .map((arr2, cci) => cci === ci ? arr2.filter((c, ccj) => cj !== ccj) : arr2)
                                                            .filter(arr2 => arr2.length > 0)
                                                    }
                                                }))}><FaTimes /></ActionIcon>
                                            </Group>
                                        })}
                                        <Group>
                                            {
                                                <Button
                                                    variant="outline"
                                                    size="xs"
                                                    leftSection={<FaPlus />}
                                                    onClick={() => setSelectedAutomation(sa => ({
                                                        ...sa, props: {
                                                            ...sa.props, conditions: [
                                                                ...sa.props.conditions.map((c, cci) => ci === cci ? [
                                                                    ...c,
                                                                    {},
                                                                ] : c)
                                                            ]
                                                        }
                                                    }))}>And</Button>}
                                            {
                                                !(selectedAutomation?.props?.conditions ?? [])[ci + 1] &&
                                                <Button
                                                    variant="outline"
                                                    size="xs"
                                                    leftSection={<FaPlus />}
                                                    onClick={() => setSelectedAutomation(sa => ({
                                                        ...sa, props: {
                                                            ...sa.props, conditions: [
                                                                ...sa.props.conditions,
                                                                [{}]
                                                            ]
                                                        }
                                                    }))}>Or</Button>}
                                        </Group>
                                    </>)
                                }
                            </Paper>}

                    <Paper p="md" mb="lg">
                        <Text size="md" fw="bold" mb="xs">Actions</Text>

                        {
                            !selectedAutomation?.props?.trigger?.key
                                ? <Text>Select the trigger to create actions.</Text>
                                : (selectedAutomation?.props?.actions ?? [{}]).map((action, ai) => {
                                    let selectedAction = actionsOptions.find(ao => ao.value === action.key);
                                    return <Paper mb="md" p="md" style={{ borderColor: '#DFDFDF' }}>
                                        <InputField
                                            name="key"
                                            size="sm"
                                            onChange={dt => changeAction(dt, ai)}
                                            value={action.key}
                                            fieldType={InputFieldTypes.SELECT}
                                            options={actionsOptions}
                                        />
                                        {[
                                            'order-assign-responsible',
                                            'order-change-status',
                                        ].includes(action.key) && <InputField
                                                name="value"
                                                size="sm"
                                                mt="xs"
                                                onChange={dt => changeAction(dt, ai)}
                                                value={action.value}
                                                fieldType={selectedAction?.options ? InputFieldTypes.SELECT : InputFieldTypes.STRING}
                                                options={selectedAction?.options ?? []}
                                            />}
                                        {[
                                            'order-new-message',
                                            'support-new-message',
                                            'support-create',
                                        ].includes(action.key) && <>
                                                <InputField
                                                    name="message"
                                                    size="sm"
                                                    placeholder="Type the message here"
                                                    mt="xs"
                                                    maxRows={5}
                                                    minRows={5}
                                                    onChange={dt => changeAction(dt, ai)}
                                                    value={action.message}
                                                    fieldType={InputFieldTypes.TEXTAREA}
                                                />
                                                <Group justify="flex-end">
                                                    <Menu>
                                                        <Menu.Target><Button variant="outline" size="xs" leftSection={<FaPlus />}>Variable</Button></Menu.Target>
                                                        <Menu.Dropdown>
                                                            {
                                                                (selectedAction?.variables ?? []).map(v => (
                                                                    <Menu.Item onClick={() => changeAction({ message: `${action.message ?? ""}{{${v.value}}}` }, ai)}>{v.label}</Menu.Item>
                                                                ))
                                                            }
                                                        </Menu.Dropdown>
                                                    </Menu>
                                                </Group>
                                            </>}
                                    </Paper>
                                })
                        }
                    </Paper>

                    <Group justify="flex-end">
                        <Button
                            disabled={!selectedAutomation?.props?.trigger?.key || (selectedAutomation?.props?.actions ?? []).length === 0}
                            color="green" loading={loadingSave} size="md" onClick={handleSave}>Save</Button>
                    </Group>
                </>
                : <>
                    <Group justify="flex-end" mb="lg">
                        <Button leftSection={<FaPlus />} onClick={() => setSelectedAutomation({})}>New Automation</Button>
                    </Group>
                    {
                        items.map(auto => (
                            <UnstyledButton mb="lg" style={{ width: '100%' }} onClick={() => setSelectedAutomation(auto)}>
                                <Paper p="md">
                                    <Group>
                                        <Box style={{ flex: 1 }}>
                                            <Text c="black" size="lg">{auto.title}</Text>
                                        </Box>
                                        <FaChevronRight style={{ margin: '0 10px' }} />
                                    </Group>
                                </Paper>
                            </UnstyledButton>
                        ))
                    }
                </>
        }
    </div>
}
