import { Avatar, Button, Group, Title } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";
import { FaNodeJs } from "react-icons/fa";

export default function Scripts() {
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);

    const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        GET_COMPANY_INTEGRATION("scripts")
            .then((res) => {
                setData(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        setLoadingSave(true)

        SET_COMPANY_INTEGRATION("scripts", { ...data })
            .then((res) => {
                setLoadingSave(false);
                loadData()
                notifications.show({ message: "Scripts saved", color: 'green' })
            })
            .catch(err => {
                setLoadingSave(false)
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    React.useEffect(() => {
        // try {
        //     var esprima = require('esprima');
        //     esprima.parseScript(data?.body);
        setIsValid(true);
        // } catch (error) {
        //     setIsValid(false);
        // }
    }, [data?.body]);

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <FaNodeJs size={30} />
            <Title order={5}>Script</Title>
        </Group>
        <InputField
            mt="md"
            name="body"
            style={{ flex: 1 }}
            value={data?.body}
            fieldType={InputFieldTypes.TEXTAREA}
            title="Additional script code"
            onChange={handleChange}
        />

        <Group justify="flex-end" mt="md">
            <Button size="md" onClick={handleSave} disabled={!isValid} loading={loadingSave} color="green" className="draw-button">Publish Code</Button>
        </Group>
    </>
}
