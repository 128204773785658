import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Modal,
  Paper,
  Text,
  Title,
  UnstyledButton
} from "@mantine/core";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { Link, Location, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import InputField, { InputFieldTypes } from "../components/input_field";
import { FORGOT_PASSWORD } from "../services/auth";
import { notifications } from "@mantine/notifications";
import { extenseRecurrenceType } from "../utility/util";
import { FaArrowLeft, FaArrowRight, FaEnvelope, FaFacebook, FaGlobe, FaInstagram, FaLinkedin, FaPhone, FaPinterest, FaSnapchat, FaTwitter, FaWhatsapp } from "react-icons/fa";
import RenderImage from "../components/render_image";
import { RenderService } from "../components/service";

const Login = () => {
  const { login, user, companyHost } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [firstImpression, setFirstImpression] = useState(false);
  const [email, setEmail] = useState("");
  const [forgot, setForgot] = useState(null);
  const [password, setPassword] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const [expandedService, setExpandedService] = useState(null);

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/";

  const brandColor = {
    "black": "#2C2C2C",
    "green": "#4CAF50",
    "yellow": "#FFD540",
    "orange": "#FF4D18",
    "blue": "#2196F3",
    "pink": "#FF87B2",
    "white": "#FAFAFA",
  }[companyHost?.appearance?.colors?.navbar ?? "#FFFFFF"];

  const contrast = {
    "black": "#FBFBFB",
    "green": "#000000",
    "yellow": "#000000",
    "orange": "#FBFBFB",
    "blue": "#FBFBFB",
    "pink": "#FBFBFB",
    "white": "#000000",
  }[companyHost?.appearance?.colors?.navbar ?? "#FFFFFF"];

  const confirmForgot = e => {
    e.preventDefault();
    const email = forgot.email;
    FORGOT_PASSWORD(email)
      .then(() => {
        setForgot(null);
        notifications.show({ title: "Forgot password", message: `A password email has been sent to ${email} with instructions to recover your password` })
      })
      .catch(err => {
        notifications.show({ title: "Oops", message: err.message, color: 'red' })
      })
  }

  useEffect(() => {
    const tim = setTimeout(() => {
      setSelectedItem(
        selectedItem < (companyHost?.services ?? []).length - 1
          ? selectedItem + 1
          : 0
      )
    }, 4000);
    return () => clearTimeout(tim);
  }, [selectedItem, companyHost?.services])

  useEffect(() => {
    setFirstImpression(!companyHost || !(companyHost?.phone && companyHost?.email))
  }, [companyHost])

  const ourServices = <>
    {/* <Title order={3} ta="center">{companyHost?.name}</Title> */}
    {/* <Title order={5} ta="center">{companyHost?.tagline ?? "Discover Our Range of Expert Services"}</Title> */}
    {/* {isMobile
    ? <>
      <Title c={"black"} order={2} ta={'center'}>Our Services</Title>
      <Text size="sm" c={"gray"} style={{opacity: 0.75}} ta={isMobile ? 'center' : 'left'}>Continue your journey.</Text>
    </>
    : <Title order={3} ta="center" c="black">Our Services</Title>} */}
    <Box style={isMobile ? {} : { flex: 1, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} mt="md" mb="md">
      <Carousel
        showThumbs={false}
        onChange={(i) => setSelectedItem(i)}
        selectedItem={selectedItem}
        centerMode
        // centerSlidePercentage={isMobile ? 100 : 100 / 2}
        centerSlidePercentage={100}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        swipeScrollTolerance={0}
        preventMovementUntilSwipeScrollTolerance
      >
        {[
          ...(companyHost?.services ?? []),
        ].map(service => (
          <UnstyledButton style={{ width: isMobile ? 'calc(100% - 5px)' : 'calc(100% - 260px)' }} onClick={() => {
            // setExpandedService(service)
            navigate("/signup/customer")
          }}>
            <Paper style={{ borderColor: '#DFDFDF', padding: 40 }} mih={isMobile ? undefined : 380}>
              <Paper mb="sm" style={{ padding: 0, border: 0, background: 'transparent' }}>
                {service.cover_image ? <RenderImage style={{ borderRadius: 10, maxHeight: '50vh' }} src={service.cover_image} width="100%" /> : <div style={{ height: 240 }}></div>}
              </Paper>
              {/* {
                        service.pricing_structure === "custom"
                        ? <Title mt="xs" c="black" order={3}>On Demand (USD)</Title>
                        : <Group align="flex-end">
                          <Title mt="xs" c="black" order={3}>{service.currency} {parseFloat(service.unity_price ?? 0).toFixed(2)}</Title>
                          {service.plan_type === "subscription" && <Text size="sm" c="gray">{`/ ${service.recurrence_type ?? "month"}`}</Text>}
                        </Group>
                      } */}
              <Title order={isMobile ? 4 : 2} mt="lg">{service.plan_name}</Title>
              <Text mt="xs" c="gray" size="sm" className="max-lines-2">{(service.description ?? "").split("\n").map((d) => <div>{d}</div>)}</Text>
            </Paper>
          </UnstyledButton>
        ))}
      </Carousel>

      {/* <Group mt="sm">
        <ActionIcon onClick={() => setSelectedItem(i => i-1)} size="xl" variant='outline'
          style={{visibility: selectedItem > 0 ? "visible" : "hidden"}}
          ><FaArrowLeft/></ActionIcon>
        <div style={{flex: 1}}></div>
        <ActionIcon onClick={() => setSelectedItem(i => i+1)} size="xl" variant='outline'
          style={{visibility: selectedItem < (companyHost?.services ?? []).length - 1 ? "visible" : "hidden"}}
        ><FaArrowRight/></ActionIcon>
      </Group> */}
    </Box>
    {/* <Text ta="center" mt="xl" c="orange" fw="bold" td="underline" size="md" onClick={() => navigate("/signup/customer")}>
      Sign Up Now
    </Text> */}
  </>

  const depoinments = <>
    <Title style={{ padding: isMobile ? 0 : '0 120px' }} order={5} ta="center" fw={500} c="black">"{
      companyHost?.appearance?.texts?.login_footer_two
      ??
      "Explore our array of top-tier services tailored to meet your needs. Sign up today and unlock the gateway to unparalleled solutions delivered by our dedicated team."
    }"</Title>
    <Title mt="md" order={5} ta="center" c="gray" fw={400} mb="xs">{
      companyHost?.appearance?.texts?.login_footer_one
      ??
      "Ready to Experience Excellence?"
    }</Title>
  </>

  const loginPage = <>
    <form
      onSubmit={(e) => {
        setLoadingLogin(true)
        e.preventDefault();
        login(email, password, () => {
          setLoadingLogin(false)
          navigate(from, { replace: true });
        }, () => {
          setLoadingLogin(false)
        });
      }}
    >
      <InputField
        mt="md"
        size="lg"
        title="E-mail"
        label={null}
        value={email}
        name="value"
        c={contrast}
        autoCapitalize="off"
        onChange={({ value }) => setEmail(value)}
      />
      <InputField
        mt="md"
        size="lg"
        title="Password"
        name="value"
        label={null}
        fieldType={InputFieldTypes.PASSWORD}
        c={contrast}
        value={password}
        autoCapitalize="off"
        onChange={({ value }) => setPassword(value)}
      />
      <UnstyledButton mt="md" onClick={() => setForgot({ email })} style={{ width: '100%' }}>
        <Text style={{ textDecoration: 'undeline' }} size="sm" c="gray" ta="right">Forgot password?</Text>
      </UnstyledButton>
      <Button loading={loadingLogin} className='draw-button' variant="filled" type="submit" mt="xl" fullWidth>
        Sign In
      </Button>
    </form>

    <Group mt="xl" style={{ justifyContent: 'center' }}>
      <Link to={companyHost ? "/signup/customer" : "/signup"} style={{ textDecoration: 'none' }}>
        <Title order={5} c="gray">Don't own an account yet?</Title>
      </Link>
      <Button onClick={() => navigate(companyHost ? "/signup/customer" : "/signup")} className='draw-button' variant="outline">
        Create Account
      </Button>
    </Group>
  </>

  const welcome = <Box mb="md">
    <Title c={contrast} order={2} ta={isMobile ? 'center' : 'left'}>Welcome Back!</Title>
    <Text size="xs" c={contrast} style={{ opacity: 0.75 }} ta={isMobile ? 'center' : 'left'}>Let's continue your journey together</Text>
  </Box>

  const firstImpressionComponent = <>
    <Title c={isMobile ? "black" : contrast} order={4} ta={isMobile ? "center" : "left"}>{companyHost?.name}</Title>
    <Group justify={isMobile ? "center" : "flex-start"} mt="40px">
      {
        (companyHost?.social ?? []).filter(s => s.type && s.url).map(s => (
          <ActionIcon onClick={() => window.open(s.url)} variant="light">{
            {
              "whatsapp": <FaWhatsapp />,
              "linkedin": <FaLinkedin />,
              "facebook": <FaFacebook />,
              "instagram": <FaInstagram />,
              "twitter": <FaTwitter />,
              "snapchat": <FaSnapchat />,
              "pinterest": <FaPinterest />,
            }[s.type]
          }</ActionIcon>
        ))
      }
    </Group>

    <Box mt={"40px"} mb={isMobile ? "sm" : "40px"} pr={isMobile ? "sm" : "0px"} pl={isMobile ? "sm" : "0px"}>
      {[
        (companyHost?.phone ?? "").replace(/ /g, "") && <>
          <Group justify={isMobile ? "center" : "flex-start"}>
            <FaPhone size={12} color={isMobile ? "black" : contrast} />
            <Text c={isMobile ? "black" : contrast} size="xs">{companyHost?.phone_code ? `+${companyHost?.phone_code}` : ""}{companyHost?.phone}</Text>
          </Group>
          <Divider mt="md" mb="md" />
        </>,
        (companyHost?.email ?? "").replace(/ /g, "") && <>
          <Group justify={isMobile ? "center" : "flex-start"}>
            <FaEnvelope size={12} color={isMobile ? "black" : contrast} />
            <Text c={isMobile ? "black" : contrast} size="xs">{companyHost?.email}</Text>
          </Group>
          <Divider mt="md" mb="md" />
        </>,
        (companyHost?.website ?? "").replace(/ /g, "") && <>
          <Group justify={isMobile ? "center" : "flex-start"}>
            <FaGlobe size={12} color={isMobile ? "black" : contrast} />
            <Text c={isMobile ? "black" : contrast} size="xs">{companyHost?.website}</Text>
          </Group>
        </>,
      ].filter(nn => nn)}
    </Box>

    <Group grow mt="40px">
      <Button onClick={() => setFirstImpression(true)}>Login</Button>
      <Button onClick={() => navigate("/signup")}>Sign Up</Button>
    </Group>
    <UnstyledButton mt="md" onClick={() => setForgot({ email })} style={{ width: '100%' }}>
      <Text style={{ textDecoration: 'undeline' }} size="sm" c="gray" ta="right">Forgot password?</Text>
    </UnstyledButton>
  </>

  return !!user
    ? <Navigate to="/" state={{ from }} />
    : (
      isMobile
        ? <div>
          <div style={{ background: '#FFFFFF' }}>
            <div style={{ padding: '20px 20px 20px 20px', textAlign: 'center' }}>
              <img alt="retainr" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{ maxHeight: 60, maxWidth: '50%' }} />
            </div>
            {!companyHost && welcome}
            {<div style={{ margin: 20, background: companyHost ? 'transparent' : `url(/assets/login.png)`, minHeight: '200px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
              {companyHost && ourServices}
            </div>}
            <div style={{ padding: 20, paddingTop: 0 }}>
              {!firstImpression
                ? firstImpressionComponent
                : loginPage}
            </div>
          </div>

          <Modal size="sm" opened={!!forgot} onClose={() => setForgot(null)}>
            <form onSubmit={confirmForgot}>
              <InputField name="email" autoCapitalize="off" placeholder="Type your e-mail" title="E-mail" value={forgot?.email} onChange={({ email }) => setForgot(f => ({ ...f, email }))} />

              <Group justify="flex-end" mt="lg">
                {/* <Button color="orange" variant="outline" type="button" mr="xs" onClick={() => setForgot(null)}>Cancel</Button> */}
                <Button color="orange" variant="filled" type="submit">Recover Password</Button>
              </Group>
            </form>
          </Modal>

          <Modal size="lg" opened={!!expandedService} onClose={() => setExpandedService(null)}>
            {expandedService && <RenderService service={expandedService} fullDescription />}

            <Group justify="flex-end" mt="lg">
              <Button color="orange" variant="filled" type="submit" onClick={() => navigate("/signup/customer")}>Signup Now</Button>
            </Group>
          </Modal>
        </div>
        : <div style={{ height: '100vh', display: 'flex', flexDirection: 'row', overflow: 'auto' }}>
          <div style={{ flex: 0.35, width: `35vw`, display: 'flex', flexDirection: 'column', background: brandColor }}>
            <div style={{ padding: isMobile ? 20 : '60px 60px 30px 60px', textAlign: 'left' }}>
              <img alt="retainr" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{ maxHeight: 80, maxWidth: '60%' }} />
            </div>
            <div style={{ flex: 1, padding: 60 }}>
              {
                !firstImpression
                  ? firstImpressionComponent
                  : <>
                    {welcome}
                    {loginPage}
                  </>
              }
            </div>
          </div>
          {<div style={{ flex: 0.65, width: `65vw`, padding: 40, display: 'flex', flexDirection: 'column', justifyContent: 'center', background: companyHost ? `#EFEFEF` : `url(/assets/login.png)`, height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            {!companyHost && <div style={{ height: 220 }}></div>}
            {companyHost && <>
              {ourServices}
              {depoinments}
            </>}
          </div>}

          <Modal size="sm" opened={!!forgot} onClose={() => setForgot(null)}>
            <form onSubmit={confirmForgot}>
              <InputField name="email" autoCapitalize="off" placeholder="Type your e-mail" title="E-mail" value={forgot?.email} onChange={({ email }) => setForgot(f => ({ ...f, email }))} />

              <Group justify="flex-end" mt="lg">
                {/* <Button color="orange" variant="outline" type="button" mr="xs" onClick={() => setForgot(null)}>Cancel</Button> */}
                <Button color="orange" variant="filled" type="submit">Recover Password</Button>
              </Group>
            </form>
          </Modal>

          <Modal size="lg" opened={!!expandedService} onClose={() => setExpandedService(null)}>
            {expandedService && <RenderService service={expandedService} fullDescription />}

            <Group justify="flex-end" mt="lg">
              <Button color="orange" variant="filled" type="submit" onClick={() => navigate("/signup/customer")}>Signup Now</Button>
            </Group>
          </Modal>
        </div>
    );
};

export default Login;
