import {
    ActionIcon,
    Box,
    Button,
    Divider,
    Grid, Group,
    Loader,
    Paper,
    Progress,
    Text,
    Title, Tooltip, UnstyledButton
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { FaCheck, FaCheckCircle, FaChevronLeft, FaFacebook, FaLinkedin, FaMicrochip, FaPinterest, FaPlus, FaReddit, FaTimesCircle, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/auth.context";
import { useMediaQuery } from "@mantine/hooks";
import InputField, { InputFieldTypes } from "../components/input_field";
import { CONSULT_LABEL_AVAILABILITY, GET_COMPANY_ONBOARDING_DATA, GET_SUGGESTION_AI, SAVE_COMPANY_ONBOARDING_DATA } from "../services/companies";
import { notifications, showNotification } from "@mantine/notifications";
import { RenderService, Service } from "../components/service";
import { countriesList, sectorList } from "../utility/util";
import axios from "axios";
import Stripe from "../components/integrations/stripe";

export default function Onboarding({ customer = false, shareLinkId = null, partner = false }) {
    const { userData, role, companyHost, loadUserData } = useAuth();

    const navigate = useNavigate();
    const { hash } = useLocation();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [steps, setSteps] = useState<any[]>([
        { tag: "brand", title: "Getting Started", key: "general", subtitle: "Welcome! First things first..." },
        { tag: "services", title: "Getting Started", key: "services", subtitle: "Welcome! First things first..." },
        { tag: "portal", title: "Getting Started", key: "whitelabel", subtitle: "Welcome! First things first..." },
        // { tag: "payment", title: "Getting Started", key: "payment", subtitle: "Welcome! First things first..." },
        { title: "Share With Your Clients", subtitle: "Welcome! First things first..." },
    ]);
    const [data, setData] = useState<any>({});
    const [selectedService, setSelectedService] = useState<number>(null);
    const [consultingLabel, setConsultingLabel] = useState<boolean>(false);
    const [validLabel, setValidLabel] = useState<boolean>(false);
    const [openStripe, setOpenStripe] = useState<boolean>(false);

    const [loadingSuggestion, setLoadingSuggestion] = useState<any>({});

    const handleChangeGeneral = (dt) => setData(c => ({ ...c, general: { ...c.general, ...dt } }))
    const handleChangeWhitelabel = (dt) => setData(c => ({ ...c, whitelabel: { ...c.whitelabel, ...dt } }))

    const navigateToTab = (index, save = false) => {
        const cb = () => {
            if (index === steps.length) {
                loadUserData();
                navigate("/");
            } else setSelectedTab(index);
        }
        const key = steps[index - 1]?.key;
        if (save && key) {
            setLoading(true)
            SAVE_COMPANY_ONBOARDING_DATA({ key, data })
                .then(() => {
                    setLoading(false);
                    cb();
                })
                .catch((err) => {
                    notifications.show({ message: err.message, color: "red" })
                    setLoading(false)
                })
        } else {
            cb();
        }
    }

    const CompanyStep = <Box>
        <InputField
            name="name"
            title="Enter your brand name"
            value={data?.general?.name}
            onChange={handleChangeGeneral}
        />

        <InputField
            name="sector"
            title="Select Your Industry"
            mt="md"
            fieldType={InputFieldTypes.SELECT}
            options={sectorList}
            value={data?.general?.sector}
            onChange={handleChangeGeneral}
        />

        <InputField
            name="country"
            title="Select Your Country"
            mt="md"
            fieldType={InputFieldTypes.SELECT}
            options={countriesList}
            value={data?.general?.country}
            onChange={handleChangeGeneral}
        />
        <InputField
            name="description"
            mt="md"
            title="Company Description"
            fieldType={InputFieldTypes.TEXTAREA}
            value={data?.general?.description}
            onChange={handleChangeGeneral}
        />
        <Group justify="right">
            <Button
                leftSection={<FaMicrochip />}
                size="xs"
                variant="light"
                disabled={!data?.general?.sector || data?.general?.sector?.length < 3}
                loading={loadingSuggestion["company_description"]} onClick={() => {
                    setLoadingSuggestion(ls => ({ ...ls, company_description: true }))
                    GET_SUGGESTION_AI({ key: "company_description", name: data?.general?.name, sector: data?.general?.sector })
                        .then(({ suggestion }) => {
                            setLoadingSuggestion(ls => ({ ...ls, company_description: false }))
                            handleChangeGeneral({ description: suggestion })
                        })
                        .catch(err => {
                            setLoadingSuggestion(ls => ({ ...ls, company_description: false }))
                            notifications.show({ message: err.message, color: 'red' })
                        })
                }}>Generate For Me</Button>
        </Group>
        {/* {!isMobile && <div style={{height: 140}}></div>} */}
    </Box>;

    const BrandStep = <Box>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <Box style={{ flex: 1, }} pr={isMobile ? undefined : "md"}>
                <InputField
                    name="image"
                    mb="md"
                    title="Upload Your Brand Logo"
                    fieldType={InputFieldTypes.IMAGE}
                    value={data?.whitelabel?.image}
                    onChange={handleChangeWhitelabel}
                />
            </Box>
            <Box style={{ flex: 1 }} pl={isMobile ? undefined : "md"}>
                <InputField
                    name="icon"
                    mb="md"
                    title="Upload Your Brand Icon"
                    fieldType={InputFieldTypes.IMAGE}
                    value={data?.whitelabel?.icon}
                    onChange={handleChangeWhitelabel}
                />
            </Box>
        </div>
    </Box>

    const WhitelabelStep = <Box>
        <Title order={3} mb="md">Customize your Client Portal</Title>

        <Title order={5} fw="normal">Navbar Color</Title>
        <Group mb="md">
            <Box style={{
                height: 30,
                width: 30,
                borderRadius: 2,
                border: '1px solid #EFEFEF',
                background: data?.whitelabel?.navbar
            }}></Box>
            <InputField
                name="navbar"
                style={{ flex: 1 }}
                fieldType={InputFieldTypes.SELECT}
                clearable={false}
                searchable={false}
                options={[
                    { label: "White", value: "white" },
                    { label: "Black", value: "black" },
                    { label: "Orange", value: "orange" },
                    { label: "Yellow", value: "yellow" },
                    { label: "Green", value: "green" },
                    { label: "Blue", value: "blue" },
                    { label: "Pink", value: "pink" },
                ]}
                value={data?.whitelabel?.navbar || "white"}
                onChange={handleChangeWhitelabel}
            />
        </Group>

        <InputField
            name="primary_color"
            title="Primary Color"
            mb="md"
            fieldType={InputFieldTypes.COLOR}
            value={data?.whitelabel?.primary_color}
            onChange={handleChangeWhitelabel}
        />

        <InputField
            name="label"
            mb="md"
            title="Company URL (Subdomain)"
            value={data?.whitelabel?.label}
            leftSection={
                consultingLabel
                    ? <Loader size="xs" />
                    : validLabel ? <FaCheckCircle color="green" /> : <FaTimesCircle color="red" />
            }
            rightSection={<Text style={{ marginLeft: -120 }}>.retainr.io</Text>}
            onChange={({ label }) => {
                label = label.replace(/ |\.|\[|\]|\(|\)|\@|\!|\*|\,|\;|\:/g, "").toLowerCase();
                handleChangeWhitelabel({ label });

                if (label?.length >= 3) {
                    setConsultingLabel(true);
                    CONSULT_LABEL_AVAILABILITY(label)
                        .then(({ available }) => {
                            setValidLabel(available);
                            setConsultingLabel(false);
                        })
                        .catch(err => {
                            setValidLabel(false)
                            setConsultingLabel(false);
                        })
                } else {
                    setValidLabel(false);
                }
            }}
        />
    </Box>;

    const ServicesStep = <Box>
        <Box mt="md">
            <Title order={3} mb="md">Add your services</Title>

            {[{}, {}, {}].map((it, i) => (
                <UnstyledButton
                    style={{ width: '100%' }}
                    onClick={() => {
                        setSelectedService(i);
                    }}
                >
                    <Paper mb="md" p="md" style={{ borderColor: '#DFDFDF', background: selectedService === i ? "#EFEFEF" : '#FDFDFD' }}>
                        {(data?.services ?? [])[i]?.plan_name ?? "Add New"}
                    </Paper>
                </UnstyledButton>
            ))}
        </Box>
    </Box>;

    const PaymentStep = <Box>
        <UnstyledButton
            style={{ width: '100%' }}
            onClick={() => {
                setOpenStripe(true)
            }}
        >
            <Paper mb="md" p="md" style={{ borderColor: '#DFDFDF', background: openStripe ? "#EFEFEF" : '#FDFDFD' }}>
                Connect your Stripe
            </Paper>
        </UnstyledButton>
    </Box>
    const StripeStep = openStripe && <Box>
        {!isMobile && <div style={{ height: 60 }}></div>}
        <Paper p="md" style={{ borderColor: '#DFDFDF', background: '#FFFFFF' }}>
            {isMobile && <ActionIcon mb="md" onClick={() => setOpenStripe(null)}><FaChevronLeft /></ActionIcon>}
            <Stripe />
        </Paper>
    </Box>

    const ServiceDetailsStep = selectedService !== undefined && selectedService !== null && selectedService >= 0 && <Box>
        {
            <Paper p="md" style={{ borderColor: '#DFDFDF', background: '#FFFFFF' }}>
                {isMobile && <ActionIcon mb="md" onClick={() => setSelectedService(null)}><FaChevronLeft /></ActionIcon>}
                <Service
                    data={(data?.services ?? [])[selectedService]}
                    onSave={(res) => {
                        setData(d => ({
                            ...d,
                            services: [{}, {}, {}].map((s, i) => i === selectedService ? res : { ...data.services[i] })
                        }));
                        setSelectedService(null);
                    }}
                />
            </Paper>
        }
    </Box>

    const ShareStep = <Box>
        <Title ta="center" order={3} mt="md">Onboard Finished!</Title>
        <Title ta="center" order={5} c="gray" mt="md">Now all you need to do is share with your clients you platform. And keep in mind that anytime you need just go to Settings or Services menu at left side of Retainr dashboard and manage all your products and informations.</Title>
        <Group align="center" justify="center" mt="xl" mb="xl">
            <Tooltip label="Share on Facebook"><ActionIcon size="xl" variant="light" onClick={() => {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
            }}><FaFacebook size={24} /></ActionIcon></Tooltip>
            <Tooltip label="Share on Whatsapp"><ActionIcon size="xl" variant="light" onClick={() => {
                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(userData?.company?.name)}%20-%20${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
            }}><FaWhatsapp size={24} /></ActionIcon></Tooltip>
            <Tooltip label="Share on Pinterest"><ActionIcon size="xl" variant="light" onClick={() => {
                window.open(`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(userData?.company?.defaultDomain)}&media=${encodeURIComponent(userData?.company?.image)}&description=${encodeURIComponent(userData?.company?.name)}`, '_blank');
            }}><FaPinterest size={24} /></ActionIcon></Tooltip>
            <Tooltip label="Share on Reddit"><ActionIcon size="xl" variant="light" onClick={() => {
                window.open(`https://www.reddit.com/submit?url=${encodeURIComponent(userData?.company?.defaultDomain)}&title=${encodeURIComponent(userData?.company?.name)}`, '_blank');
            }}><FaReddit size={24} /></ActionIcon></Tooltip>
            <Tooltip label="Share on Twitter"><ActionIcon size="xl" variant="light" onClick={() => {
                window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(userData?.company?.defaultDomain)}&text=${encodeURIComponent(userData?.company?.name)}`, '_blank');
            }}><FaTwitter size={24} /></ActionIcon></Tooltip>
            <Tooltip label="Share on Linkedin"><ActionIcon size="xl" variant="light" onClick={() => {
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
            }}><FaLinkedin size={24} /></ActionIcon></Tooltip>
        </Group>
        <UnstyledButton style={{ width: '100%' }} onClick={() => {
            window.open(`http://${userData?.company?.defaultDomain}`)
        }}>
            <Text ta="center" c="orange" size="sm" mt="xs" style={{ textDecoration: 'underline' }}>{userData?.company?.defaultDomain}</Text>
        </UnstyledButton>
    </Box>;

    const continueForm = <>
        <Box mt="xl">
            <Button
                onClick={() => navigateToTab(selectedTab + 1, true)}
                className='draw-button'
                type="button"
                loading={loading}
                color="orange"
                fullWidth
                size="lg">{selectedTab + 1 === steps.length ? "Go to Dashboard" : "Continue"}</Button>
        </Box>
        <Box mt="md">
            {selectedTab + 1 < steps.length && <Button variant="transparent" fullWidth c="gray" onClick={() => {
                loadUserData();
                navigate("/");
            }}>Skip Onboarding</Button>}
        </Box>
    </>

    const activeForm = [
        isMobile
            ? {
                principal: <>
                    {BrandStep}
                    {CompanyStep}
                </>
            }
            : { principal: BrandStep, secondary: CompanyStep, continueRight: true },
        { principal: ServicesStep, secondary: ServiceDetailsStep },
        { principal: WhitelabelStep },
        //   { principal: PaymentStep, secondary: StripeStep },
        { principal: ShareStep },
    ].filter(nn => nn)[selectedTab]

    useEffect(() => {
        if (data?.general && !data?.general?.country) {
            axios.get("https://ipinfo.io")
                .then(({ data }) => {
                    handleChangeGeneral({ country: data.country });
                })
                .catch(err => { });
        }
    }, [data])

    useEffect(() => {
        setLoading(true);
        GET_COMPANY_ONBOARDING_DATA()
            .then(res => {
                setValidLabel(true);
                setData(res);
                setLoading(false);
            })
            .catch(err => {
                notifications.show({ title: "Oops", message: err.message, color: 'red' })
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if (hash) {
            const tabIndex = steps.findIndex(s => s.tag === hash.split("#")[1])
            setSelectedTab(tabIndex > -1 ? tabIndex : 0);
        }
    }, [hash, steps]);

    if (!role) return null;
    return role?.profile?.title === "Client"
        ? <Navigate to="/" />
        : (
            <div style={{
                backgroundImage: customer ? undefined : `url(/assets/onboarding.png)`,
                backgroundColor: `#6a6a6a`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                minHeight: '100vh',
                zIndex: 0,
                ...(
                    isMobile
                        ? {
                            padding: 20,
                        }
                        : {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: activeForm?.secondary ? '10vh 10vw' : '10vh 25vw',
                        }
                )
            }}>
                <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, background: '#00000055', zIndex: -1 }}></div>
                <Paper p="md" style={{ borderColor: '#DFDFDF' }}>
                    {isMobile && activeForm?.secondary
                        ? activeForm?.secondary
                        : <Grid>
                            <Grid.Col span={{ base: 12, md: activeForm?.secondary ? 7 : 12 }}>
                                <div style={{ display: 'flex', margin: '40px 0', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <img alt="retainr" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{ maxHeight: 30 }} />
                                </div>

                                <Group style={{ padding: isMobile ? '0 4px' : '0 60px' }}>
                                    <ActionIcon onClick={() => setSelectedTab(i => i - 1)} style={{ visibility: selectedTab === 0 ? 'hidden' : 'visible' }}><FaChevronLeft /></ActionIcon>
                                    <Box style={{ flex: 1 }}>
                                        <Text ta="center" c="gray" size="sm">{steps[selectedTab]?.subtitle}</Text>
                                        <Title ta="center" c="black" order={2}>{steps[selectedTab]?.title}</Title>
                                    </Box>
                                    <ActionIcon style={{ visibility: 'hidden' }}><FaChevronLeft /></ActionIcon>
                                </Group>

                                <Box style={{ padding: isMobile ? '20px 10px 0 10px' : '40px 60px 0 60px' }}>
                                    <Box style={{ padding: '0 0 40px 0' }}>
                                        <Progress
                                            value={(((selectedTab ?? 0) + 1) / (steps.length)) * 100}
                                            color="orange"
                                        />
                                    </Box>
                                    <form onSubmit={e => { e.preventDefault(); navigateToTab(selectedTab + 1, true) }} style={{ overflowY: 'auto' }}>
                                        {activeForm?.principal}
                                        {(!activeForm.continueRight || !activeForm.secondary) && continueForm}
                                    </form>
                                </Box>
                            </Grid.Col>
                            {activeForm?.secondary && !isMobile && <Grid.Col span={{ base: 12, md: 5 }}>
                                {activeForm.secondary}
                                {activeForm.continueRight && continueForm}
                            </Grid.Col>}
                        </Grid>}
                </Paper>
            </div>
        );
};
