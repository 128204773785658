import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";
import { Avatar, Button, Group, Title } from "@mantine/core";

export default function GoogleAnalytics() {
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        GET_COMPANY_INTEGRATION("google_analytics")
            .then((res) => {
                setData(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        setLoadingSave(true)
        SET_COMPANY_INTEGRATION("google_analytics", { ...data })
            .then((res) => {
                setLoadingSave(false)
                notifications.show({ message: "Connected on Google Analytics", color: 'green' })
            })
            .catch(err => {
                setLoadingSave(false)
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <Avatar size={40} src="/assets/google_analytics.png" />
            <Title order={5}>Google Analytics</Title>
        </Group>
        <InputField
            mt="md"
            name="key"
            value={data?.key}
            onChange={handleChange}
            title="Measurement ID"
            placeholder="Should start with a “G-” or “GT-”"
        />

        <Group justify="flex-end" mt="md">
            <Button size="md" onClick={handleSave} loading={loadingSave} color="green" className="draw-button">Connect</Button>
        </Group>
    </>
}
