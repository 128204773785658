import { ActionIcon, Badge, Box, Button, Checkbox, Divider, Grid, Group, Image, Loader, Paper, Switch, Text, Title, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaCheck, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import InputField, { InputFieldTypes } from '../components/input_field';
import { PaymentForm } from '../components/payment_form';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { SAVE_COMPANY_PLAN, SEARCH_COUPON } from '../services/companies';
import { GET_ALL_PLANS } from '../services/plans';
import { extenseRecurrenceType } from '../utility/util';
import { GET_STRIPE_CONFIGS, SAVE_PAYMENT_METHOD } from '../services/payments';
import { Link } from 'react-router-dom';
import { Config } from '../constants/config.constant';

export default function ChoosePlan({ onClose = null }) {
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [agree, setAgree] = useState(false);
    const [plans, setPlans] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({});
    const [searchCoupon, setSearchCoupon] = useState<string>("");
    const [selectedRecurrencyType, setSelectedRecurrencyType] = useState<string>(Config.DEFAULT_RECURRENCY_TYPE);
    const [coupons, setCoupons] = useState<any>({});

    const selectedPlan = plans.find(s => s._id === formData?.plan);

    const { startPlan, agents } = useApp();
    const { planData, userData, loadUserData, ip } = useAuth();

    let vl = parseFloat(selectedPlan?.service?.unity_price ?? 0);
    if (coupons[searchCoupon]) vl = coupons[searchCoupon].type === "static"
        ? vl - coupons[searchCoupon].value
        : vl * ((100 - coupons[searchCoupon].value) / 100);

    const handleSave = async () => {
        if (agree) {
            setLoadingSave(true);
            if (formData?.payment.method === "new-card") {
                try {
                    const { retainr } = await GET_STRIPE_CONFIGS("retainr")
                    const stripe = require('stripe')(retainr);
                    const pm = await stripe.paymentMethods.create({
                        type: 'card',
                        card: formData?.payment?.card,
                    })
                    const method = await SAVE_PAYMENT_METHOD("retainr", pm, { email: userData.company.email, name: userData.company.name })
                    finishSave(method._id, { push: true });
                } catch (err) {
                    setLoadingSave(false)
                    notifications.show({ message: err?.message ?? "Stripe not configured" });
                }
            } else {
                finishSave(formData?.payment?.method);
            }
        } else {
            notifications.show({ message: "To continue you need to agree with Terms of Service and Refund Policy", color: "yellow" })
        }
    }

    const finishSave = (method, others = {}) => {
        setLoadingSave(true);

        SAVE_COMPANY_PLAN({ ...formData, payment: { method, ...others }, coupon: coupons[searchCoupon] })
            .then(res => {
                setLoadingSave(false);
                startPlan(false);
                if (res.payed) {
                    loadUserData();
                    if (selectedPlan) {
                        (window as any).fbq('track', 'Subscribe', {
                            ip,
                            name: userData?.user?.name,
                            email: userData?.user?.email,
                            phone: `+${userData?.user?.phone_code}${userData?.user?.phone.replace(/\(|\)|\-|\.| /g, "")}`,
                            plan: selectedPlan?.service?.plan_name,
                            currency: selectedPlan?.service?.currency,
                            value: selectedPlan?.service?.unity_price
                        });
                    }
                } else {
                    if (res.return_url) window.open(res.return_url, "_blank");
                    else notifications.show({ message: "You need to finish the payment process in your bank. Check for notifications and approve the transaction to finish", color: "yellow" })
                }
            })
            .catch(err => {
                setLoadingSave(false)
                notifications.show({ message: err.message, color: "red" })
            })
    }

    const loadPlans = () => {
        setLoadingPlans(true);

        GET_ALL_PLANS()
            .then(res => {
                setPlans(res);
                setLoadingPlans(false);
            })
            .catch(err => {
                setLoadingPlans(false)
                notifications.show({ message: err.message, color: "red" })
            })
    }

    useEffect(() => {
        loadPlans()
    }, []);

    useEffect(() => {
        if (searchCoupon.length > 3) SEARCH_COUPON(searchCoupon)
            .then(res => {
                setCoupons(c => ({ ...c, [searchCoupon]: res }))
            })
            .catch(err => {
            })
    }, [searchCoupon]);

    useEffect(() => {
        setFormData(fd => ({ ...fd, seats: (planData?.seats || []).length <= 0 ? 1 : (planData?.seats || []).length }))
    }, [planData]);

    return <Box p="md">
        {!selectedPlan && <>
            <Group align='flex-start'>
                <div style={{ flex: 1 }}>
                    <Title order={3}>Plan Packages</Title>
                    <Title order={5} style={{ fontWeight: 'normal' }}>Pricing that suits all.</Title>
                </div>
                {onClose && <ActionIcon onClick={onClose} variant='outline'>
                    <FaTimes />
                </ActionIcon>}
            </Group>

            <Group pb="md" pt="md" style={{ justifyContent: 'center' }} align='center'>
                <Group gap={5} style={{ borderRadius: 40, padding: '10px', border: '1px solid #DFDFDF', whiteSpace: 'nowrap' }}>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "month" ? '1px solid #DFDFDF' : 0 }}
                        color="orange"
                        onClick={() => setSelectedRecurrencyType("month")}
                        variant={selectedRecurrencyType === "month" ? "filled" : "light"} size="xs">Monthly</Button>
                    <Button
                        style={{ borderRadius: 20, width: 100, border: selectedRecurrencyType === "year" ? '1px solid #DFDFDF' : 0 }}
                        color="orange"
                        onClick={() => setSelectedRecurrencyType("year")}
                        variant={selectedRecurrencyType === "year" ? "filled" : "light"} size="xs">Yearly</Button>
                </Group>
            </Group>

            {loadingPlans && <Loader mt="xl" />}
            <Grid mt="md" mb="md">
                {plans
                    .filter(p => (selectedRecurrencyType === "month" && !p.service.recurrence_type) || p.service.recurrence_type === selectedRecurrencyType)
                    .map(plan => (
                        <Grid.Col span={{ base: 12, md: 4 }}>
                            <UnstyledButton style={{ width: '100%' }} onClick={() => {
                                setFormData(fd => ({ ...fd, plan: plan._id }))
                            }}>
                                <Paper shadow='xs' p="md" style={{ borderColor: '#DFDFDF' }}>
                                    <Title order={3}>{plan.service.plan_name}</Title>
                                    <Text c="gray" size="sm">{plan.service.description}</Text>

                                    <Group mt="md" align='flex-end' gap="1px">
                                        <Title c="black" size="22px">{plan.service.currency} {parseFloat(plan.service.unity_price).toFixed(2)}</Title>
                                        <Text c="black" size="sm">/ {plan.service.plan_type === "subscription" ? extenseRecurrenceType(plan.service.recurrence_type) : "One-time"}</Text>
                                    </Group>
                                    <Group align='center' justify='flex-end' mt="xs">
                                        {(plan.service.badges ?? []).map(s => (<Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge>))}
                                    </Group>
                                    {/* <Text c="gray" size="sm">Per user</Text> */}
                                    <Button mt="md" color="orange" fullWidth style={{ borderWidth: 1, borderColor: 'black' }}>Get Started</Button>
                                </Paper>
                            </UnstyledButton>
                        </Grid.Col>
                    ))}
            </Grid>
        </>}

        {selectedPlan && <>
            <Grid>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group>
                        <ActionIcon size="xl" variant="outline" color="gray" onClick={() => setFormData(fd => ({ ...fd, plan: null }))}><FaArrowLeft /></ActionIcon>
                        <Title order={3}>Checkout</Title>
                    </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Paper style={{ borderColor: "#DFDFDF" }} p="xl">
                        <Group align="flex-start">
                            <Box style={{ flex: 1 }}>
                                <Title order={3}>{selectedPlan.service.plan_name}</Title>
                                <Text c="black" size="sm">{selectedPlan.service.description}</Text>
                            </Box>
                            <Box>
                                <Group align='flex-end' gap="1px">
                                    <Title c="black" size="22px">{selectedPlan.service.currency} {parseFloat(selectedPlan.service.unity_price).toFixed(2)}</Title>
                                    <Text c="black" size="sm">/ {selectedPlan.service.plan_type === "subscription" ? extenseRecurrenceType(selectedPlan.service.recurrence_type) : "One-time"}</Text>
                                </Group>
                                {/* <Text mb="md" c="gray" size="sm">Per user</Text>
                                <InputField
                                    title="Add Users"
                                    name="seats"
                                    fieldType={InputFieldTypes.QUANTITY}
                                    value={formData.seats}
                                    onChange={({seats}) => {
                                        let minimun = (planData?.seats || []).length;
                                        minimun = (minimun > 1 ? minimun : 1);
                                        if(parseInt(seats) >= minimun){
                                            setFormData(fd => ({...fd, seats}));
                                        }
                                    }}
                                    size="xs"
                                /> */}
                            </Box>
                        </Group>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 7 }}>
                    <Paper style={{ borderColor: "#DFDFDF" }} p="xl">
                        {
                            (vl) > 0 && <>
                                <Title order={4}>Payment Method</Title>
                                <Box mt="sm">
                                    <PaymentForm
                                        data={formData?.payment}
                                        methods={userData.paymentMethods}
                                        onChange={(dt) => setFormData(fd => ({
                                            ...fd,
                                            payment: { ...fd?.payment, ...dt }
                                        }))}
                                    />
                                </Box>
                            </>
                        }
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 5 }}>
                    <Paper style={{ borderColor: "#DFDFDF" }} p="xl">
                        <Title order={4}>Total</Title>

                        <Group grow mt="lg">
                            <Text>Active Seats</Text>
                            <Text ta="right" fw="bold">{formData.seats}</Text>
                        </Group>

                        <InputField
                            title="Coupon Code"
                            name="cp"
                            mt="sm"
                            size={"md"}
                            value={searchCoupon}
                            onChange={({ cp }) => { setSearchCoupon(cp) }}
                            rightSection={coupons[searchCoupon] && <FaCheck />}
                        />

                        <Paper mt="md" p="md" style={{ background: '#EFEFEF', borderColor: "#DFDFDF" }}>
                            <Text size="xs">Agency White-label</Text>
                            {/* <Text size="xs" mt="xs" c="gray">{selectedPlan.service.currency} {vl.toFixed(2)} x {formData.seats ?? 1} agency users</Text> */}
                            <Text size="xs" mt="xs" c="gray">{selectedPlan.service.currency} {vl.toFixed(2)}</Text>
                            <Divider c="#DFDFDF" mt="md" mb="md" />
                            <Text size="xs">Total Amount</Text>
                            <Text size="xs" mt="xs" fw="bold">{selectedPlan.service.currency} {((formData.seats ?? 1) * vl).toFixed(2)} ({selectedPlan.service.plan_type === "one-time" ? "One Time" : extenseRecurrenceType(selectedPlan.service.recurrence_type)})</Text>
                        </Paper>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Checkbox
                        checked={agree}
                        mb="md"
                        onChange={() => setAgree(a => !a)}
                        label={<Text size="xs" c="gray" mb="md">I agree to the <Link to="https://www.retainr.io/terms-conditions" target="_blank">Terms of Service</Link></Text>}
                    />
                    <Button loading={loadingSave} onClick={handleSave} mt="lg" variant="filled" style={{ borderWidth: 1, borderColor: 'black' }} fullWidth size="lg" color="orange" rightSection={<FaLongArrowAltRight />}>Confirm</Button>
                </Grid.Col>
            </Grid>
        </>}
    </Box>
}
