import {
    AppShell,
    Box,
    Button,
    Group,
    LoadingOverlay,
    Text
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { default as React, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_PUBLIC_JOB, GET_PUBLIC_LINK_DATA } from '../services/auth';
import Signup from './signup';
import Home from './home';
import { GET_JOB_DETAILS } from '../services/jobs';
import { formatLabel } from '../utility/util';

export default function PublicPage() {
    const { key } = useParams()
    const navigate = useNavigate()
    const app = useApp()
    const { userData, companyHost } = useAuth();

    const isMobile = useMediaQuery('(max-width: 1200px)');

    const [data, setData] = useState<any>(null);
    const [order, setOrder] = useState<any>(null);
    const [selectedService, setSelectedService] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const some = (companyHost?.services ?? []).find(s => (formatLabel(s.label ?? s.plan_name)) === formatLabel(key))
        if (some) {
            if (userData) navigate(`/?service=${some._id}`);
            else setSelectedService(some);
        } else setSelectedService(null);
    }, [userData, companyHost, key]);

    useEffect(() => {
        if (key?.startsWith("o-")) {
            if (companyHost) {
                setLoading(true)
                GET_PUBLIC_JOB(key.replace("o-", ""))
                    .then(res => {
                        setLoading(false)
                        setOrder(res)
                    })
                    .catch(err => {
                        setLoading(false)
                    })
            }
        } else {
            setLoading(true)
            GET_PUBLIC_LINK_DATA(key)
                .then(res => {
                    setLoading(false)
                    setData(res)
                })
                .catch(err => {
                    setLoading(false)
                })
        }
    }, [key, companyHost])

    if (selectedService || order) return <Signup customer order={order} service={selectedService ?? order?.service} />;
    if (data?.type === "affiliate") return <Signup shareLinkId={data?._id} />;

    return <AppShell
        // navbarOffsetBreakpoint="sm"
        // asideOffsetBreakpoint="sm"
        // fixed
        padding={0}
    >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>
            {!data?.type && !loading && <>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} p="md" mb="xl">
                    <Group justify='center'>
                        <img alt="retainr" src={companyHost?.image ? companyHost.image : `/logo.png`} style={{ maxHeight: 80, maxWidth: '70%' }} />
                    </Group>
                    <Text mt="xl" size="xl" ta="center">This page is not available!</Text>
                    <Group mt="xl" justify='center'>
                        <Button className='draw-button' onClick={() => window.location.href = "https://app.retainr.io"}>Return</Button>
                    </Group>
                </Box>
            </>}
        </div>
        <LoadingOverlay visible={loading} />
    </AppShell>
}
