import { ActionIcon, Avatar, Badge, Box, Button, Card, Grid, Group, Image, LoadingOverlay, Paper, Progress, Text, Title, UnstyledButton } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { FaAppStore, FaCalendarDay, FaChartPie, FaCheck, FaChevronDown, FaChevronRight, FaChevronUp, FaClock, FaComment, FaCopy, FaGooglePlay, FaLayerGroup, FaList, FaMinus, FaPeopleCarry, FaPlus, FaSuitcase, FaThumbsUp, FaTimes, FaUser, FaUsers, FaWrench } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SimpleHeader from '../components/simple_header';
import RevenueChart from '../components/charts/revenue_chart';
import { GET_ALL_CUSTOMERS } from "../services/customers";
import { GET_ALL_JOBS } from "../services/jobs";
import { useAuth } from "../contexts/auth.context";
import { GET_MEMBERS, GET_TASKS, GET_TOTALS } from "../services/statistics";
import NewJobPage from "./new_job";
import { useLanguage } from "../contexts/language.context";
import { formatMoney, getExtenseDatetime, useQuery } from "../utility/util";
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { useApp } from "../contexts/app.context";
import { GET_ALL_SERVICES } from "../services/services";
import { GET_COMPANY_INTEGRATION } from "../services/companies";
import moment from "moment";

export default function Home() {
    const navigate = useNavigate();
    const { role, user: currentUser, userData } = useAuth();
    const { str } = useLanguage();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const query = useQuery();
    const service = query.get("service");
    const { mobileApp } = useApp();

    const [jobs, setJobs] = useState([]);
    const [loadingItems, setLoadingItems] = React.useState<boolean>(false);

    const [totals, setTotals] = useState<any>({});
    const [services, setServices] = useState<any>([]);

    const clipboard = useClipboard();

    const month = ((totals?.revenue ?? []).find(item => moment(item.date).format("YYYY-MM") === moment().format("YYYY-MM"))?.value ?? 0)
    const previous = ((totals?.revenue ?? []).find(item => moment(item.date).format("YYYY-MM") === moment().subtract(1, "month").format("YYYY-MM"))?.value ?? 0)
    const revenueDiff = month === 0 ? (previous > 0 ? -100 : 0) : previous > month ? -((1 - (month / previous)) * 100) : (((month / previous) - 1) * 100);

    const onboardingSteps = [
        {
            done: !!userData?.onboarding?.brand,
            key: "brand",
            icon: '/assets/onboarding-brand-gray.svg',
            done_icon: '/assets/onboarding-brand.svg',
            title: "Create your Retainr profile",
            subtitle: "Add your logo, description, and professional information.",
        },
        {
            done: !!userData?.onboarding?.services,
            key: "services",
            icon: '/assets/onboarding-service-gray.svg',
            done_icon: '/assets/onboarding-service.svg',
            title: "Setup your Services",
            subtitle: "Create your service packages of the services you’re offering and start selling instantly.",
        },
        {
            done: !!userData?.onboarding?.portal,
            key: "portal",
            icon: '/assets/onboarding-portal-gray.svg',
            done_icon: '/assets/onboarding-portal.svg',
            title: "Customise your Client Portal",
            subtitle: "Create your own whitelabel client portal tailored to your preferences.",
        },
        // {
        //     done: !!userData?.onboarding?.payment,
        //     key: "payment",
        //     icon: '/assets/onboarding-payment-gray.svg',
        //     done_icon: '/assets/onboarding-payment.svg',
        //     title: "Connect your Stripe",
        //     subtitle: "Get your payments setup...",
        // },
    ]

    useEffect(() => {
        GET_ALL_SERVICES()
            .then(res => { setServices(res); }).catch(err => { })
    }, [])

    useEffect(() => {
        GET_TOTALS({})
            .then(res => {
                setTotals(res);
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
            })
    }, [])

    const loadJobs = () => {
        setLoadingItems(true)
        GET_ALL_JOBS()
            .then(res => {
                setJobs(res);
                setLoadingItems(false)
            })
            .catch(err => {
                setLoadingItems(false)
                notifications.show({ title: "Oops", message: err.message, color: 'red' })
            })
    }

    useEffect(loadJobs, [])

    return <div style={{ position: 'relative' }}>
        {role?.profile?.title !== "Client" && <SimpleHeader
            title="Dashboard"
            subtitle={str("DASHBOARD.WELCOME").replace("{{name}}", currentUser?.name)}
        />}

        <LoadingOverlay visible={loadingItems} />
        <Grid>
            <Grid.Col span={{ base: 12, xs: 12, md: role?.profile?.title === "Client" ? 12 : 7 }}>
                {role?.profile?.title !== "Client"
                    ? <Grid>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Paper p="xl" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: 0, cursor: 'pointer', background: '#f8ecea' }} onClick={() => navigate("/orders")}>
                                <Group>
                                    <ActionIcon style={{ background: 'white', height: 65, width: 65, borderRadius: 40 }}><FaList size={22} color="#2a2a2a" /></ActionIcon>
                                    <Box style={{ flex: 1 }} pl="md" pr="md">
                                        <Text size="sm" c="#eaa092" fw="bold">{str("DASHBOARD.TOTAL_ORDERS")}</Text>
                                        <Title order={2}>{(totals?.orders ?? 0)}</Title>
                                    </Box>
                                </Group>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Paper p="xl" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: 0, cursor: 'pointer', background: '#f2effe' }} onClick={() => navigate("/orders")}>
                                <Group>
                                    <ActionIcon style={{ background: 'white', height: 65, width: 65, borderRadius: 40 }}><FaWrench size={22} color="#2a2a2a" /></ActionIcon>
                                    <Box style={{ flex: 1 }} pl="md" pr="md">
                                        <Text size="sm" c="#b695c5" fw="bold">{str("DASHBOARD.ORDERS_IN_PROGRESS")}</Text>
                                        <Title order={2}>{(totals?.orders_in_progress ?? 0)}</Title>
                                    </Box>
                                </Group>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Paper p="xl" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: 0, cursor: 'pointer', background: '#e6f8ff' }} onClick={() => navigate("/settings#users")}>
                                <Group>
                                    <ActionIcon style={{ background: 'white', height: 65, width: 65, borderRadius: 40 }}><FaUsers size={22} color="#2a2a2a" /></ActionIcon>
                                    <Box style={{ flex: 1 }} pl="md" pr="md">
                                        <Text size="sm" c="#3e7fbb" fw="bold">{str("DASHBOARD.TOTAL_MEMBERS")}</Text>
                                        <Title order={2}>{totals?.members ?? 0}</Title>
                                    </Box>
                                </Group>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Paper p="xl" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: 0, cursor: 'pointer', background: '#ebf8ee' }} onClick={() => navigate("/clients")}>
                                <Group>
                                    <ActionIcon style={{ background: 'white', height: 65, width: 65, borderRadius: 40 }}><FaUser size={22} color="#2a2a2a" /></ActionIcon>
                                    <Box style={{ flex: 1 }} pl="md" pr="md">
                                        <Text size="sm" c="#67cc8f" fw="bold">{str("DASHBOARD.CLIENTS")}</Text>
                                        <Title order={2}>{(totals?.clients ?? 0)}</Title>
                                    </Box>
                                </Group>
                            </Paper>
                        </Grid.Col>
                    </Grid>
                    : <>
                        <NewJobPage
                            justify={"flex-start"}
                            service={service}
                            title={
                                <Group align="center" mb="lg">
                                    <Title order={4}>{str("DASHBOARD.CLIENT_WELCOME_1").replace("{{name}}", currentUser?.name)}</Title>
                                    <Title order={4} c="gray">{str("DASHBOARD.CLIENT_WELCOME_2").replace("{{companyName}}", userData?.company?.name)}</Title>
                                </Group>
                            }
                        />
                    </>}
            </Grid.Col>
            {role?.profile?.title !== "Client" && userData?.company?.defaultDomain && <>
                <Grid.Col span={{ base: 12, md: 5 }}>
                    {onboardingSteps.filter(os => os.done).length !== onboardingSteps.length && <Paper style={{ minHeight: 375, borderColor: '#FFFFFF' }} p="md" mb="xs">
                        <Group mb="xl" grow={!isMobile} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                            <Title order={5}>Onboarding Steps</Title>
                            <Box>
                                <Text><b>Progress:</b> {(100 * (onboardingSteps.filter(os => os.done).length / onboardingSteps.length)).toFixed(0)}% Complete...</Text>
                                <Progress
                                    value={100 * (onboardingSteps.filter(os => os.done).length / onboardingSteps.length)}
                                />
                            </Box>
                        </Group>
                        {
                            onboardingSteps.map(step => {
                                return <UnstyledButton
                                    key={step.key}
                                    style={{ width: '100%' }}
                                    onClick={() => navigate(`/welcome#${step.key}`)}
                                >
                                    <Paper style={{ borderColor: '#FFFFFF', background: step.done ? '#F6F6F6' : '#FFFFFF' }} p="md" mb="xs">
                                        <Group>
                                            <Image style={{ width: 30 }} src={step.done ? step.done_icon : step.icon} />
                                            <Box style={{ flex: 1 }}>
                                                <Title size={14}>{step.title}</Title>
                                                <Title size={12} fw={500}>{step.subtitle}</Title>
                                            </Box>
                                            {!step.done && <Text size="md">Pending...</Text>}
                                        </Group>
                                    </Paper>
                                </UnstyledButton>
                            })
                        }
                    </Paper>}
                </Grid.Col>

                {role?.profile?.title !== "Client" && <Grid.Col span={{ base: 12, md: 4 }}>
                    <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                        <Title order={4} mb="md">{str("DASHBOARD.REVENUE")}</Title>
                        <Title size={32} mb="md">{formatMoney(month)}</Title>
                        <Badge
                            color={
                                revenueDiff === 0 ? "gray" : revenueDiff > 0 ? "green" : "red"
                            }
                            leftSection={
                                revenueDiff === 0 ? <FaMinus /> : revenueDiff > 0 ? <FaChevronUp /> : <FaChevronDown />
                            }
                        >{revenueDiff.toFixed(0)}%</Badge>
                    </Paper>
                </Grid.Col>}

                <Grid.Col span={{ base: 12, md: 4 }}>
                    {/* {
                        mobileApp?.developer?.apple_url
                        mobileApp?.developer?.play_store_url
                        mobileApp?.playstore?.icon
                        mobileApp?.applestore?.icon
                    } */}
                    {
                        mobileApp?.developer?.deployed
                            ? <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                                <Group align="center" mb="xs">
                                    <Title order={4} style={{ flex: 1 }}>{userData?.company?.name} Mobile App is live</Title>
                                    {userData?.user?.isAdmin && <ActionIcon onClick={() => navigate("/mobile-app")}><FaWrench /></ActionIcon>}
                                </Group>

                                <Group mt="xl">
                                    {mobileApp?.developer?.play_store_url && <Box style={{ flex: 1 }}>
                                        <Paper onClick={() => window.open(mobileApp?.developer?.play_store_url, "_blank")} p="xs" style={{ cursor: 'pointer', display: 'flex', borderRadius: 10, flexDirection: 'row', alignItems: 'center', background: '#000000' }}>
                                            <FaGooglePlay size={18} color="white" />
                                            <Text ta="center" style={{ flex: 1, padding: '0 10px' }} size="10px" c="white">Get it on Play Store</Text>
                                        </Paper>
                                    </Box>}
                                    {mobileApp?.developer?.apple_url && <Box style={{ flex: 1 }}>
                                        <Paper onClick={() => window.open(mobileApp?.developer?.apple_url, "_blank")} p="xs" style={{ cursor: 'pointer', display: 'flex', borderRadius: 10, flexDirection: 'row', alignItems: 'center', background: '#000000' }}>
                                            <FaAppStore size={18} color="white" />
                                            <Text ta="center" style={{ flex: 1, padding: '0 10px' }} size="10px" c="white">Download on the Apple Store</Text>
                                        </Paper>
                                    </Box>}
                                </Group>
                            </Paper>
                            : mobileApp?.developer?.bought
                                ? role?.profile?.title !== "Client" && <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                                    <Group align="center" mb="xs">
                                        <Title order={4} style={{ flex: 1 }}>Mobile App Publishment</Title>
                                        {userData?.user?.isAdmin && <ActionIcon onClick={() => navigate("/mobile-app")}><FaWrench /></ActionIcon>}
                                    </Group>
                                    <Box mt="md">
                                        <Progress
                                            value={100 * ([
                                                mobileApp?.developer?.apple_url,
                                                mobileApp?.developer?.play_store_url,
                                                mobileApp?.playstore?.icon,
                                                mobileApp?.applestore?.icon,
                                            ].filter(nn => nn).length / 4)}
                                        />
                                        <Text ta="right"><b>Progress:</b> {(100 * ([
                                            mobileApp?.developer?.apple_url,
                                            mobileApp?.developer?.play_store_url,
                                            mobileApp?.playstore?.icon,
                                            mobileApp?.applestore?.icon,
                                        ].filter(nn => nn).length / 4)).toFixed(0)}%</Text>
                                        <Text size="sm" c="gray" ta="right">Deploy Forecast: {getExtenseDatetime(moment(mobileApp?.developer?.bought).add(15, "days"), false)}</Text>
                                    </Box>
                                </Paper>
                                : role?.profile?.title !== "Client" && <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                                    <Group align="center" mb="xs">
                                        <Title order={4} style={{ flex: 1 }}>Get Your Own Branded Mobile App</Title>
                                        {userData?.user?.isAdmin && <ActionIcon onClick={() => navigate("/mobile-app")}><FaWrench /></ActionIcon>}
                                    </Group>
                                    <Group align="center">
                                        <Box style={{ flex: 1 }}>
                                            <Text size="sm">Upgrade to Android & iOS App for Your Agency, Team & Clients.</Text>
                                        </Box>
                                    </Group>
                                    <Group mt="md" justify="flex-end">
                                        <Button rightSection={<FaChevronRight />} onClick={() => {
                                            window.open(`https://www.retainr.io/mobile-app`, "_blank")
                                        }} color="orange">Learn More</Button>
                                    </Group>
                                </Paper>
                    }
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 4 }}>
                    <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                        <Title order={4} mb="xs">{str("DASHBOARD.SHARE_TITLE")}</Title>
                        <Text size="sm" mb="md">{str("DASHBOARD.SHARE_SUBTITLE")}</Text>

                        <Paper style={{ borderColor: '#FFFFFF' }}>
                            <Group>
                                {/* <ActionIcon color="gray" onClick={() => {
                                    window.open(`http://${userData?.company?.defaultDomain}`)
                                }}><FaCopy /></ActionIcon> */}
                                <Text style={{ flex: 1, wordBreak: "break-all" }} size="md" c="gray">https://{userData?.company?.defaultDomain}</Text>
                                <Box style={{ flex: 1 }}>
                                    <Button leftSection={<FaCopy />} onClick={() => {
                                        clipboard.copy(`https://${userData?.company?.defaultDomain}`);
                                        window.open(`https://${userData?.company?.defaultDomain}`, "_blank")
                                        notifications.show({ message: "Copied to clipboard", color: "yellow" })
                                    }} color="gray">View Live</Button>
                                </Box>
                            </Group>
                        </Paper>
                    </Paper>
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 6 }}>
                    <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                        <Title order={4} mb="md">{str("DASHBOARD.REVENUE_STATS")}</Title>
                        <RevenueChart />
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                    <Paper style={{ borderColor: '#FFFFFF', minHeight: 230 }} p="xl">
                        <Text size="md" c="gray">{str("DASHBOARD.RECENT_ORDERS")}</Text>
                        {
                            (totals?.recent ?? []).map(({ job, datetime }) => {
                                const status = (job.job_status ?? []).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status;
                                return <Paper p="md" mt="md" style={{ cursor: 'pointer' }} onClick={() => navigate(`/orders/${job._id}`)}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Box style={{ flex: 1 }}>
                                            <Text size="sm" fw="bold">{job.name}</Text>
                                            <Text size="xs" c="gray" fw={300}>{(job.description ?? "").replace("\n", " ").slice(0, 100)}</Text>
                                        </Box>
                                        <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <Group gap={4}>
                                                <FaCalendarDay color="black" />
                                                <Text size="xs">{moment(datetime).format("DD MMM")}</Text>
                                            </Group>
                                            <Group mt="xs">
                                                {status && <Button variant="outline" style={{ padding: '3px 10px' }}
                                                    color={{
                                                        "in-progress": "yellow",
                                                        "done": "green",
                                                    }[status?.category] || "gray"}
                                                    size="xs">{status?.title}</Button>}
                                            </Group>
                                        </div>
                                    </div>
                                </Paper>
                            })
                        }
                    </Paper>
                </Grid.Col>
            </>}
        </Grid>
    </div>
}
