import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, Image, Paper, Switch, Table, Text, Title, UnstyledButton } from '@mantine/core';
import { default as React, useEffect, useState } from 'react';
import InputField, { InputFieldMasks, InputFieldTypes } from '../components/input_field';
import RenderImage from '../components/render_image';
import { extenseRecurrenceType, formatLabel, getCurrencies } from '../utility/util';
import { notifications } from '@mantine/notifications';
import { CREATE_SERVICE, UPDATE_SERVICE } from '../services/services';
import { GET_SUGGESTION_AI } from '../services/companies';
import { FaChevronCircleLeft, FaEdit, FaMicrochip, FaPlus, FaPlusSquare, FaTrash } from 'react-icons/fa';
import { useAuth } from '../contexts/auth.context';

export const RenderService = ({ service, selected = false, onClick = null, fullDescription = false }: any) => {
    return <UnstyledButton style={{ width: '100%' }} onClick={onClick}>
        <Paper p="md" style={selected ? { borderWidth: 3 } : { borderColor: "#DFDFDF" }} c="orange">
            <Paper style={{ padding: 0, border: 0, background: '#EFEFEF' }}>
                {service.cover_image ? <RenderImage src={service.cover_image} width="100%" fit="cover" /> : <div style={{ height: 150 }}></div>}
            </Paper>
            <Title order={4} mt="sm">{service.plan_name}</Title>
            <Text mt="xs" size="sm" c="gray" className={fullDescription ? "" : "max-lines-2"}>{(service.description ?? "").split("\n").map((d) => <div style={{ minHeight: 14 }}>{d}</div>)}</Text>
            {
                (service.pricing_structure === "custom" || service.pricing_table)
                    ? <Text mt="xs" size="sm">On Demand ({service.currency})</Text>
                    : <Text mt="xs" size="sm">From {service.currency} {parseFloat(service.unity_price ?? 0).toFixed(2)}</Text>
            }
            <Text c="gray" size="xs">{service.plan_type === "subscription" ? extenseRecurrenceType(service.recurrence_type) : "One-time"}</Text>
            <Group align='center' justify='flex-end' mt="xs">
                {(service.badges ?? []).map(s => (<Badge variant='filled' c={s.textColor} color={s.color}>{s.text}</Badge>))}
            </Group>
        </Paper>
    </UnstyledButton>
}

export const Service = ({ onSave, forms = [], data: previousData, for_plan = false }) => {
    const { userData, user } = useAuth();

    const [data, setData] = useState<any>({});
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSuggestion, setLoadingSuggestion] = useState<any>({});
    const [insertingItem, setInsertingItem] = useState<any>(null);

    const handleSave = () => {
        let params = { ...data }
        const id = params._id;
        delete params._id;
        (!id ? CREATE_SERVICE({ ...params, for_plan }) : UPDATE_SERVICE(id, params))
            .then(res => {
                notifications.show({ message: "Service updated!", color: "green" })
                setLoadingSave(false);
                onSave && onSave(res);
            })
            .catch(err => {
                setLoadingSave(false);
                notifications.show({ message: err.message, color: 'red' })
            })
    }

    useEffect(() => {
        if (previousData) setData(previousData);
        else setData({});
    }, [previousData]);

    return <>
        <Group>
            <InputField
                name="currency"
                title="Currency"
                value={data.currency}
                fieldType={InputFieldTypes.SELECT}
                clearable={false}
                onChange={({ currency }) => { setData(us => ({ ...us, currency })) }}
                options={getCurrencies()}
            />
            <InputField
                name="plan_name"
                title="Service Title"
                placeholder='Service of your name'
                value={data.plan_name}
                onChange={({ plan_name }) => {
                    setData(us => ({ ...us, plan_name }));
                }}
                style={{ flex: 1 }}
            />
        </Group>
        <InputField
            mt="lg"
            name="label"
            title="Label"
            placeholder='The slug that will be added to the domain URL when creating magic links'
            value={formatLabel(data.label ?? data.plan_name ?? "")}
            onChange={({ label }) => { setData(us => ({ ...us, label })) }}
            style={{ flex: 1 }}
        />
        <InputField
            mt="lg"
            name="description"
            title="Product description"
            value={data.description}
            fieldType={InputFieldTypes.TEXTAREA}
            onChange={({ description }) => { setData(us => ({ ...us, description })) }}
            style={{ flex: 1 }}
        />
        <Group justify="right">
            <Button
                leftSection={<FaMicrochip />}
                size="xs"
                variant="light"
                disabled={!data?.plan_name || data?.plan_name?.length < 3}
                loading={loadingSuggestion["service_description"]} onClick={() => {
                    setLoadingSuggestion(ls => ({ ...ls, service_description: true }))
                    GET_SUGGESTION_AI({ key: "service_description", title: data?.plan_name, company_name: userData?.company?.name })
                        .then(({ suggestion }) => {
                            setLoadingSuggestion(ls => ({ ...ls, service_description: false }))
                            setData(us => ({ ...us, description: suggestion }))
                        })
                        .catch(err => {
                            setLoadingSuggestion(ls => ({ ...ls, service_description: false }))
                            notifications.show({ message: err.message, color: 'red' })
                        })
                }}>AI generate your description</Button>
        </Group>
        <InputField
            mt="lg"
            name={"cover_image"}
            title="Add Cover Image"
            fieldType={InputFieldTypes.IMAGE}
            value={data.cover_image}
            onChange={({ cover_image }) => { setData(us => ({ ...us, cover_image })) }}
        />
        <Group justify="right">
            <Button
                leftSection={<FaMicrochip />}
                size="xs"
                variant="light"
                disabled={!data?.plan_name || !data?.description || data?.plan_name?.length < 3 || data?.description?.length < 3}
                loading={loadingSuggestion["service_cover"]} onClick={() => {
                    setLoadingSuggestion(ls => ({ ...ls, service_cover: true }))
                    GET_SUGGESTION_AI({ key: "service_cover", title: data?.plan_name, sector: data?.general?.sector })
                        .then(({ suggestion }) => {
                            setLoadingSuggestion(ls => ({ ...ls, service_cover: false }))
                            setData(us => ({ ...us, cover_image: suggestion }))
                        })
                        .catch(err => {
                            setLoadingSuggestion(ls => ({ ...ls, service_cover: false }))
                            notifications.show({ message: err.message, color: 'red' })
                        })
                }}>AI generate your cover image</Button>
        </Group>
        <Box mt="lg">
            <Group mb="xs">
                <Text style={{ flex: 1 }} size="md">Promotional Badges</Text>
                <ActionIcon variant='light' color="orange"
                    onClick={() => {
                        setData(us => ({ ...us, badges: [...(us.badges ?? []), { color: "#000000", textColor: "#FFFFFF" }] }))
                    }}
                ><FaPlusSquare /></ActionIcon>
            </Group>
            {(data?.badges ?? []).length === 0 && <Text c="gray" size="sm" ta="center">Add badges to your service</Text>}
            {(data?.badges ?? []).map((badge, bi) => <Group>
                <InputField
                    name="color"
                    mt="md"
                    fieldType={InputFieldTypes.COLOR}
                    title="Background Color"
                    style={{ flex: 1 }}
                    value={badge.color}
                    onChange={({ color }) => { setData(us => ({ ...us, badges: (us.badges ?? []).map((b, bj) => bi === bj ? { ...b, color } : b) })) }}
                />
                <InputField
                    name="textColor"
                    mt="md"
                    fieldType={InputFieldTypes.COLOR}
                    title="Text Color"
                    style={{ flex: 1 }}
                    value={badge.textColor}
                    onChange={({ textColor }) => { setData(us => ({ ...us, badges: (us.badges ?? []).map((b, bj) => bi === bj ? { ...b, textColor } : b) })) }}
                />
                <InputField
                    name="text"
                    mt="md"
                    title="Text"
                    style={{ flex: 1 }}
                    value={badge.text}
                    onChange={({ text }) => { setData(us => ({ ...us, badges: (us.badges ?? []).map((b, bj) => bi === bj ? { ...b, text } : b) })) }}
                />
                <ActionIcon variant='light' color="orange"
                    onClick={() => {
                        setData(us => ({ ...us, badges: (us.badges ?? []).filter((b, bj) => bj !== bi) }))
                    }}
                ><FaTrash /></ActionIcon>
            </Group>)}
        </Box>

        <Divider mt="lg" />
        {forms.length > 0 && <>
            <InputField
                name="forms"
                mt="md"
                title="Link Forms"
                placeholder="Select the form you want to link to this service package"
                fieldType={InputFieldTypes.SELECT}
                multiple
                value={data?.forms ?? []}
                onChange={({ forms }) => { setData(us => ({ ...us, forms })) }}
                options={forms.map(f => ({ label: f.title, value: f._id }))}
            />

            <Divider mt="lg" />
        </>}
        <InputField
            mt="lg"
            name="plan_type"
            title="Plan Type"
            value={data.plan_type}
            fieldType={InputFieldTypes.BOX}
            clearable={false}
            searchable={false}
            onChange={({ plan_type }) => { setData(us => ({ ...us, plan_type })) }}
            options={[
                { label: "One-time", value: "one-time" },
                { label: "Subscription", value: "subscription" },
            ]}
        />

        <InputField
            mt="md"
            name="pt"
            title="Use pricing table"
            value={data.pricing_table}
            fieldType={InputFieldTypes.CHECKBOX}
            onChange={({ pt }) => { setData(us => ({ ...us, pricing_table: !!pt })) }}
        />

        {data.plan_type === "subscription" && <InputField
            mt="md"
            name="offer_trial"
            title="Offer a trial period ?"
            value={data.offer_trial}
            fieldType={InputFieldTypes.CHECKBOX}
            onChange={({ offer_trial }) => { setData(us => ({ ...us, offer_trial })) }}
        />}
        {data.plan_type === "subscription" && <InputField
            mt="md"
            name="charge_setup"
            title="Charge a one-time setup fee ?"
            value={data.charge_setup}
            fieldType={InputFieldTypes.CHECKBOX}
            onChange={({ charge_setup }) => { setData(us => ({ ...us, charge_setup })) }}
        />}
        {data.plan_type === "subscription" && <>
            <Divider mt="md" mb="md" />
            <Title order={5}>Subscription Period</Title>
            <InputField
                mt="md"
                name="st"
                title="Unlimited Billing Cycles"
                value={data.subscription_period_type === "unlimited"}
                fieldType={InputFieldTypes.CHECKBOX}
                onChange={({ st }) => { setData(us => ({ ...us, subscription_period_type: st ? "unlimited" : null })) }}
            />
            <InputField
                mt="md"
                name="st"
                title="Limited Billing Cycles"
                value={data.subscription_period_type === "limited"}
                fieldType={InputFieldTypes.CHECKBOX}
                onChange={({ st }) => { setData(us => ({ ...us, subscription_period_type: st ? "limited" : null })) }}
            />
        </>}
        {data.plan_type === "subscription" && data.offer_trial && <InputField
            mt="md"
            name="trial_period"
            title="Trial Period"
            value={data.trial_period}
            fieldType={InputFieldTypes.NUMBER}
            onChange={({ trial_period }) => { setData(us => ({ ...us, trial_period })) }}
        />}

        {
            data.pricing_table
                ? <>
                    <Divider mt="lg" />
                    <Title order={4} mt="lg">Pricing Table</Title>
                    <Text size="sm" c="gray">Clients can  add / remove the items of the service they would like to pay for and customize their orders.</Text>

                    {!insertingItem && (data.table ?? []).length > 0 && <>
                        <Grid>
                            {data.table.sort((a, b) => a.index > b.index ? 1 : -1).map((t, i) => <Grid.Col span={{ base: 12, md: 12 }}>
                                <Paper p="sm" mt="xs" style={{ borderColor: '#DFDFDF' }}>
                                    <Group align='flex-start'>
                                        <Text style={{ flex: 1 }} size="lg">{t.title}</Text>
                                        <Box>
                                            <ActionIcon onClick={() => setInsertingItem(t)} variant="outline" color="gray" ml="xs"><FaEdit /></ActionIcon>
                                            <ActionIcon onClick={() => setData(d => ({ ...d, table: d.table.filter((t2, j) => i !== j) }))} variant="outline" color="red" ml="xs"><FaTrash /></ActionIcon>
                                        </Box>
                                    </Group>
                                    <Text size="sm" mt="xs" c="gray">{t.description}</Text>
                                    {t.pricing_structure === "custom"
                                        ? <Text mt="xs">On demand pricing</Text>
                                        : <Text mt="xs">{data.currency} {parseFloat(t.unity_price ?? "0").toFixed(2)}{t.pricing_structure === "fixed" ? "" : " per unity"}</Text>
                                    }
                                    {(t.min_quantity || t.max_quantity) && <Text c="gray" size="sm">
                                        {[t.min_quantity ? `From ${t.min_quantity}` : "", t.max_quantity ? `To ${t.max_quantity}` : ""].filter(nn => nn).join(" ")} items
                                    </Text>}
                                    {t.unit && <Text c="gray" size="sm">Unit of measurement: {t.unit}</Text>}
                                </Paper>
                            </Grid.Col>)}
                        </Grid>
                        <Button onClick={() => setInsertingItem({})} mt="md" leftSection={<FaPlus />}>Add Item</Button>
                    </>}
                    {(insertingItem || (data.table ?? []).length === 0) && <Paper p="sm" mt="sm" style={{ borderColor: '#DFDFDF' }}>
                        <Grid>
                            <Grid.Col span={{ base: 12, md: 12 }}>
                                {(data.table ?? []).length > 0 && <ActionIcon onClick={() => setInsertingItem(null)}><FaChevronCircleLeft /></ActionIcon>}
                                <InputField
                                    title="Item Title *"
                                    name="title"
                                    mt="md"
                                    value={insertingItem?.title}
                                    onChange={({ title }) => { setInsertingItem(us => ({ ...us, title })) }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12 }}>
                                <InputField
                                    title="Item description"
                                    name="description"
                                    value={insertingItem?.description}
                                    fieldType={InputFieldTypes.TEXTAREA}
                                    onChange={({ description }) => { setInsertingItem(us => ({ ...us, description })) }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12 }}>
                                <InputField
                                    title="Pricing Structure *"
                                    name="pricing_structure"
                                    placeholder="Define the package pricing"
                                    value={insertingItem?.pricing_structure}
                                    fieldType={InputFieldTypes.BOX}
                                    clearable={false}
                                    searchable={false}
                                    onChange={({ pricing_structure }) => { setInsertingItem(us => ({ ...us, pricing_structure })) }}
                                    options={[
                                        { label: "Fixed Pricing", value: "fixed" },
                                        { label: "Quantity Pricing", value: "quantity" },
                                        { label: "On Demand (Custom)", value: "custom" },
                                    ]}
                                />
                                <Text c="gray" size="xs">{{
                                    fixed: "ⓘ The cost of the service package is set at a predetermined, fixed amount.",
                                    quantity: "ⓘ The cost of the service package is determined based on the quantity or volume of items or services.",
                                    custom: "ⓘ The cost of the service package is flexible and dependent on the agency or client",
                                }[insertingItem?.pricing_structure]}</Text>
                            </Grid.Col>
                            {insertingItem?.pricing_structure !== "custom" && <>
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <InputField
                                        title={insertingItem?.pricing_structure === "fixed" ? "Price *" : "Item Price *"}
                                        name="unity_price"
                                        placeholder="The cost of the item"
                                        value={insertingItem?.unity_price}
                                        mask={InputFieldMasks.MONEY}
                                        onChange={({ unity_price }) => { setInsertingItem(us => ({ ...us, unity_price })) }}
                                    />
                                </Grid.Col>
                                {data?.plan_type === "one-time" && insertingItem?.pricing_structure !== "custom" && <Grid.Col span={{ base: 12, md: 4 }}>
                                    <InputField
                                        name="delivery_period"
                                        title="Delivery Period (per item) *"
                                        value={insertingItem?.delivery_period}
                                        fieldType={InputFieldTypes.NUMBER}
                                        onChange={({ delivery_period }) => { setInsertingItem(us => ({ ...us, delivery_period })) }}
                                    />
                                </Grid.Col>}
                            </>}
                            <Grid.Col span={{ base: 12, md: 4 }}>
                                <InputField
                                    title={"Unit of measurement"}
                                    name="unit"
                                    value={insertingItem?.unit}
                                    onChange={({ unit }) => { setInsertingItem(us => ({ ...us, unit })) }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12 }}></Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <InputField
                                    title="Min. Quantity"
                                    name="min_quantity"
                                    value={insertingItem?.min_quantity}
                                    fieldType={InputFieldTypes.NUMBER}
                                    onChange={({ min_quantity }) => { setInsertingItem(us => ({ ...us, min_quantity })) }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <InputField
                                    title="Max. Quantity"
                                    name="max_quantity"
                                    value={insertingItem?.max_quantity}
                                    fieldType={InputFieldTypes.NUMBER}
                                    onChange={({ max_quantity }) => { setInsertingItem(us => ({ ...us, max_quantity })) }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12 }}>
                                <Button variant="filled" color="gray"
                                    disabled={
                                        !insertingItem?.title ||
                                        !insertingItem?.pricing_structure ||
                                        (insertingItem?.pricing_structure !== "custom" && !insertingItem?.unity_price) ||
                                        (data?.plan_type === "one-time" && insertingItem?.pricing_structure !== "custom" && !insertingItem?.delivery_period)
                                    }
                                    onClick={() => {
                                        setData(d => ({
                                            ...d, table: ![null, undefined].includes(insertingItem?.index)
                                                ? (d.table ?? []).map((t, i) => i === insertingItem?.index ? { ...t, ...insertingItem } : t)
                                                : [...(d.table ?? []), ({ ...insertingItem, index: (d.table ?? []).length })]
                                        }));
                                        setInsertingItem(null);
                                    }}
                                >Update Item</Button>
                            </Grid.Col>

                        </Grid>
                    </Paper>}
                    {data.plan_type === "subscription" && <>
                        <InputField
                            name="recurrence_period"
                            title="Recurrence Period"
                            value={data.recurrence_period || 1}
                            fieldType={InputFieldTypes.NUMBER}
                            onChange={({ recurrence_period }) => { setData(us => ({ ...us, recurrence_period: recurrence_period <= 0 ? 1 : recurrence_period })) }}
                        />
                        <InputField
                            name="recurrence_type"
                            title="Recurrence Type"
                            value={data.recurrence_type || "month"}
                            fieldType={InputFieldTypes.SELECT}
                            placeholder='Period'
                            searchable={false}
                            clearable={false}
                            onChange={({ recurrence_type }) => { setData(us => ({ ...us, recurrence_type })) }}
                            options={[
                                { value: "month", label: "Month(s)" },
                                { value: "week", label: "Week(s)" },
                                { value: "year", label: "Year(s)" },
                            ]}
                        />
                        {data.subscription_period_type === "limited" && <>
                            <InputField
                                name="recurrence_cycles"
                                title='Subscription Cycles'
                                mt="md"
                                value={data.recurrence_cycles}
                                fieldType={InputFieldTypes.NUMBER}
                                onChange={({ recurrence_cycles }) => { setData(us => ({ ...us, recurrence_cycles })) }}
                            />
                        </>}
                    </>}
                </>
                : <>
                    <InputField
                        mt="lg"
                        name="pricing_structure"
                        title="Pricing Structure"
                        value={data.pricing_structure}
                        fieldType={InputFieldTypes.BOX}
                        clearable={false}
                        searchable={false}
                        onChange={({ pricing_structure }) => { setData(us => ({ ...us, pricing_structure })) }}
                        options={[
                            { label: "Fixed Pricing", value: "fixed" },
                            { label: "Quantity Pricing", value: "quantity" },
                            { label: "On Demand (Custom)", value: "custom" },
                        ]}
                        style={{ flex: 1 }}
                    />
                    <Text c="gray" size="xs">{{
                        fixed: "ⓘ The cost of the service package is set at a predetermined, fixed amount.",
                        quantity: "ⓘ The cost of the service package is determined based on the quantity or volume of items or services.",
                        custom: "ⓘ The cost of the service package is flexible and dependent on the agency or client",
                    }[data.pricing_structure]}</Text>


                    {data.plan_type === "one-time" && data.pricing_structure !== "custom" && <InputField
                        mt="md"
                        name="delivery_period"
                        title="Delivery Period (per item) *"
                        value={data.delivery_period}
                        fieldType={InputFieldTypes.NUMBER}
                        onChange={({ delivery_period }) => { setData(us => ({ ...us, delivery_period })) }}
                    />}

                    <Divider mt="md" mb="md" />
                    {
                        data.plan_type === "subscription" &&
                        data.charge_setup &&
                        <Group align='flex-end'>
                            <Text mt="md">Charge</Text>
                            {data.pricing_structure !== "custom"
                                ? <InputField
                                    name="setup_value"
                                    value={data.setup_value}
                                    mask={InputFieldMasks.MONEY}
                                    placeholder='Setup value'
                                    onChange={({ setup_value }) => { setData(us => ({ ...us, setup_value })) }}
                                />
                                : <Text c="orange">negotiated value</Text>}
                            <Text mt="md">for setup.</Text>
                        </Group>
                    }
                    <Group mt="md">
                        <Text>Charge</Text>
                        {data.pricing_structure !== "custom"
                            ? <InputField
                                name="unity_price"
                                value={data.unity_price}
                                mask={InputFieldMasks.MONEY}
                                placeholder='Price'
                                onChange={({ unity_price }) => { setData(us => ({ ...us, unity_price })) }}
                            />
                            : <Text c="orange">negotiated value</Text>}
                        <Text>{data.pricing_structure === "fixed" ? "" : "per item"}</Text>
                        {data.plan_type === "subscription" && <>
                            <Text>every</Text>
                            <Group>
                                <InputField
                                    name="recurrence_period"
                                    value={data.recurrence_period || 1}
                                    fieldType={InputFieldTypes.NUMBER}
                                    onChange={({ recurrence_period }) => { setData(us => ({ ...us, recurrence_period: recurrence_period <= 0 ? 1 : recurrence_period })) }}
                                    style={{ width: 50 }}
                                />
                                <InputField
                                    name="recurrence_type"
                                    value={data.recurrence_type || "month"}
                                    fieldType={InputFieldTypes.SELECT}
                                    placeholder='Period'
                                    searchable={false}
                                    clearable={false}
                                    onChange={({ recurrence_type }) => { setData(us => ({ ...us, recurrence_type })) }}
                                    style={{ width: 120 }}
                                    options={[
                                        { value: "month", label: "Month(s)" },
                                        { value: "week", label: "Week(s)" },
                                        { value: "year", label: "Year(s)" },
                                    ]}
                                />
                            </Group>
                        </>}
                        {data.plan_type === "subscription" && (
                            data.subscription_period_type === "limited" ? <>
                                <Text>during</Text>
                                <InputField
                                    name="recurrence_cycles"
                                    value={data.recurrence_cycles}
                                    fieldType={InputFieldTypes.NUMBER}
                                    style={{ width: 50 }}
                                    onChange={({ recurrence_cycles }) => { setData(us => ({ ...us, recurrence_cycles })) }}
                                />
                                <Text>cycles</Text>
                            </>
                                : <Text>unlimited cycles</Text>
                        )}
                    </Group>
                </>}

        <Button style={{ backgroundColor: 'black' }} fullWidth mt="md" size="lg" loading={loadingSave} onClick={handleSave}>
            {data._id ? "Update Service Package" : "Create Service Package"}
        </Button>
    </>
}
