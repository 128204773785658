import { Avatar, Button, Group, Title } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";

export default function Zapier() {
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const clipboard = useClipboard();

    // const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        GET_COMPANY_INTEGRATION("zapier")
            .then((res) => {
                setData(res)
            })
            .catch(err => {
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        setLoadingSave(true)

        SET_COMPANY_INTEGRATION("zapier", { ...data }, [{ key: "key" }])
            .then((res) => {
                setLoadingSave(false);
                loadData()
                notifications.show({ message: "Re-generated zapier API KEY", color: 'green' })
            })
            .catch(err => {
                setLoadingSave(false)
                notifications.show({ title: "Ouch.", message: err.message, color: 'red' })
            })
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <Avatar size={40} src="/assets/zapier.png" />
            <Title order={5}>Zapier</Title>
        </Group>
        <Group align="flex-end">
            <InputField
                mt="md"
                name="key"
                style={{ flex: 1 }}
                value={data?.key}
                fieldType={InputFieldTypes.PASSWORD}
                readOnly
                title="API KEY"
            />
            <Button onClick={() => {
                clipboard.copy(data?.key);
                notifications.show({ message: "Copied to clipboard", color: "yellow" })
            }} color="gray">Copy</Button>
        </Group>

        <Group justify="flex-end" mt="md">
            <Button className="draw-button" size="md" onClick={handleSave} loading={loadingSave} color="green">Re-Generate</Button>
        </Group>
    </>
}
